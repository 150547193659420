import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { navigate } from 'gatsby';
import { withPrefix } from 'gatsby-link';

import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import * as userAuthActions from '../../redux/actions/userAuthActions';
import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';

import { runAllInternetOfferImports } from '../../api/adminApi';

import AddressSearch from '../AddressSearch';

import './index.css';

function HeaderUserAuth({ authUser, actions, siteConfig, serviceAddress }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showAddressSearch, setShowAddressSearch] = useState(false);
  const [addressSearched, setAddressSearched] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'header-user-auth-popover' : undefined;

  return (
    <div className="header-user-auth">
      <IconButton aria-describedby={id} onClick={handleClick}>
        <PersonIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        className="header-user-auth-popover">
        Logged in as {authUser.User.Email}
        {authUser.User.IsAdmin ? (
          <div className="admin-menu">
            <span className="admin-title">Admin Only</span>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setShowAddressSearch(true);
                handleClose();
              }}>
              Address Search
            </Button>
          </div>
        ) : null}
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            actions.logOutUser();
            actions.pageLoading(true, 'Logging out...');
            window.location.reload();
          }}>
          Log Out
        </Button>
      </Popover>

      {showAddressSearch ? (
        <Dialog
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          className={
            'address-search' + (addressSearched ? ' address-searched' : '')
          }>
          <DialogTitle id="form-dialog-title">
            <img
              src={withPrefix('/images/logo-icon.svg')}
              className="logo-icon"
            />
            Address Search
            <IconButton
              style={{ float: 'right' }}
              aria-label="close"
              onClick={() => {
                setShowAddressSearch(null);
                setAddressSearched(false);
              }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent width="100%">
            <AddressSearch
              modal={true}
              onSelected={() => {
                setShowAddressSearch(false);
                setAddressSearched(false);
              }}
              hideSearchBy
              onAddressSearched={() => setAddressSearched(true)}
            />
          </DialogContent>
        </Dialog>
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  siteConfig: state.commonData.siteConfig,
  webSocket: state.webSocket,
  authUser: state.authUser,
  serviceAddress: state.serviceAddress
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      logOutUser: bindActionCreators(userAuthActions.logOutUser, dispatch),
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      serviceAddressUpdated: bindActionCreators(
        serviceAddressActions.serviceAddressUpdated,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderUserAuth);
