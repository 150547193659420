import React from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { withPrefix } from 'gatsby-link';

function GeneralPopup({
  title,
  message,
  closeFunc,
  fullScreen,
  closeLabel,
  submitLabel,
  submitFunc,
  submitDisabled,
  className,
  hideActions
}) {
  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className={'general-popup' + (className ? ' ' + className : '')}
      fullScreen={fullScreen}>
      <DialogTitle id="form-dialog-title">
        <IconButton
          style={{ float: 'right' }}
          aria-label="close"
          onClick={() => {
            closeFunc();
          }}>
          <CloseIcon />
        </IconButton>
        <img src={withPrefix('/images/logo-icon.svg')} className="logo-icon" />
        {title}
      </DialogTitle>
      <DialogContent width="100%">{message}</DialogContent>
      {!hideActions ? (
        <DialogActions>
          {submitFunc ? (
            <Button
              disabled={submitDisabled}
              variant="contained"
              color="secondary"
              onClick={() => submitFunc()}>
              {submitLabel ? submitLabel : 'Submit'}
            </Button>
          ) : null}
          <Button onClick={() => closeFunc()}>
            {closeLabel ? closeLabel : 'Continue'}
          </Button>
        </DialogActions>
      ) : null}
    </Dialog>
  );
}

export default GeneralPopup;
