import * as types from '../actions/actionTypes';
import initialState from './initialState';

import { getCurrentRoute } from '../../api/dataApi';

export default function webSocketReducer(
  state = initialState.webSocket,
  action
) {
  switch (action.type) {
    case types.WEB_SOCKET_CONNECTING: {
      let result = {
        ...state,
        connecting: true,
        connected: false,
        disconnected: false,
        connectionId: null,
        reconnecting: false
      };

      if (!state.initted) {
        if (typeof window !== 'undefined') {
          const route = getCurrentRoute();
          if (route !== '' && route !== '/' && route !== '/landing-mi') {
            result.pageLoading = true;
            result.loadingMessage = 'Loading...';
          }
        }
      }

      return result;
    }
    case types.WEB_SOCKET_RECONNECTING:
      return {
        ...state,
        connecting: true,
        connected: false,
        disconnected: false,
        connectionId: null,
        reconnecting: true
      };

    case types.WEB_SOCKET_CONNECTED:
      return {
        ...state,
        connecting: false,
        connected: true,
        disconnected: false,
        connectionId: action.connectionId,
        reconnecting: false,
        initted: true
      };

    case types.WEB_SOCKET_DISCONNECTED:
      return {
        ...state,
        connecting: false,
        connected: false,
        disconnected: true,
        connectionId: null,
        reconnecting: false
      };
    case types.WEB_PAGE_LOADING:
      return {
        ...state,
        pageLoading: action.isLoading,
        loadingMessage: action.loadingMessage
      };
    case types.INVITATION_FOUND:
      return {
        ...state,
        invitation: action.invitation
      };
    case types.MOCK_ROUTE_UPDATED:
      return {
        ...state,
        mockRoute: action.mockRoute,
        closeFunc: action.closeFunc
      };
    case types.LANDING_PAGE_SET:
      return {
        ...state,
        landingPage: { ...action.landingPage }
      };
    case types.AJAX_CONNECTED:
      return {
        ...state,
        ajaxConnected: true,
        hasServiceAddress: action.hasServiceAddress
      };
    default:
      return state;
  }
}
