import React, { createContext, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { deviceDetect } from 'react-device-detect';
import {
  webSocketConnected,
  webSocketConnecting,
  webSocketReconnecting,
  webSocketDisconnected
} from './redux/actions/webSocketActions';

import * as userAuthActions from './redux/actions/userAuthActions';
import * as serviceAddressActions from './redux/actions/serviceAddressActions';
import * as webSocketActions from './redux/actions/webSocketActions';
import * as residentOnboardingActions from './redux/actions/residentOnboardingActions';
import * as userAuthApi from './api/userAuthApi';

import { hubConnection, signalR } from 'signalr-no-jquery';

import { getCurrentRoute } from './api/dataApi';
import { navigate } from 'gatsby';

const baseURL = process.env.GATSBY_SIGNALR_URL;

const WebSocketContext = createContext(null);

export { WebSocketContext };
export default ({ children }) => {
  var ws = null;
  let hub = null;

  const dispatch = useDispatch();
  const loadingTimeout = useRef();
  const pulseInterval = useRef();

  function connectToApi(connectionId) {
    userAuthApi
      .saveConnectionId(connectionId, deviceDetect())
      .then((response) => {
        if (loadingTimeout.current) {
          clearTimeout(loadingTimeout.current);
          loadingTimeout.current = null;
        }

        if (!connectionId) {
          dispatch(webSocketActions.pageLoading(false));
          dispatch(
            webSocketActions.ajaxConnected(response.data.HasServiceAddress)
          );
        }

        if (
          response.data &&
          response.data.HasServiceAddress &&
          response.data.ServiceAddressDashboard
        ) {
          if (loadingTimeout.current) {
            clearTimeout(loadingTimeout.current);
            loadingTimeout.current = null;
          }

          dispatch(
            serviceAddressActions.serviceAddressUpdated({
              ...response.data.ServiceAddressDashboard,
              force: true
            })
          );

          if (
            response.data.ServiceAddressDashboard &&
            response.data.ServiceAddressDashboard.ResidentOnboarding
          ) {
            dispatch(
              residentOnboardingActions.residentOnboardingUpdated({
                ...response.data.ServiceAddressDashboard.ResidentOnboarding
              })
            );
          }
        }

        if (response.data && response.data.Invitation) {
          dispatch(webSocketActions.invitationFound(response.data.Invitation));
        }

        if (
          response.data &&
          response.data.UserLoginResult &&
          response.data.UserLoginResult.User &&
          response.data.UserLoginResult.User.Token
        ) {
          dispatch(
            userAuthActions.userLoggedIn({
              ...response.data.UserLoginResult
            })
          );
        }

        dispatch(webSocketConnected(hub.connection.id));

        if (!pulseInterval.current) {
          pulseInterval.current = setInterval(() => {
            userAuthApi.saveUserMetric({ Pulse: 'Heartbeat' });
          }, [5000]);
        }
      });
  }

  useEffect(() => {
    loadingTimeout.current = setTimeout(() => {
      if (typeof window !== 'undefined') {
        const route = getCurrentRoute();
        if (route !== '' && route !== '/') {
          dispatch(webSocketActions.pageLoading(true, 'Loading...'));
        }
      }
    }, 0);

    console.log('connect ajax');
    connectToApi(null);
  }, []);

  function stateChanged(state) {
    switch (state.newState) {
      case signalR.connectionState.connecting:
        dispatch(webSocketConnecting());
        break;
      case signalR.connectionState.connected:
        if (loadingTimeout.current) {
          clearTimeout(loadingTimeout.current);
          loadingTimeout.current = null;
        }

        console.log('connect socket');
        connectToApi(hub.connection.id);
        break;
      case signalR.connectionState.reconnecting:
        dispatch(webSocketReconnecting());
        break;
      case signalR.connectionState.disconnected:
        dispatch(webSocketDisconnected());
        break;
    }
  }

  function sendMessage(message) {
    return hub.invoke('sendMessage', message);
  }

  if (!hub) {
    hub = {
      connection: hubConnection(baseURL, {
        useDefaultPath: false
      })
    };

    hub.proxy = hub.connection.createHubProxy('notificationsHub');

    hub.connection.logging = true;

    hub.on = function (event, fn) {
      hub.proxy.on(event, fn);
    };

    hub.invoke = function (method, args) {
      return hub.proxy.invoke.apply(hub.proxy, arguments);
    };

    hub.disconnect = function () {
      hub.connection.stop();
    };

    hub.connect = function () {
      return hub.connection.start();
    };

    hub.on('sendMessage', function (message) {
      console.log(message);
    });

    hub.on('serviceAddressUpdated', function (serviceAddress) {
      console.log('serviceAddressUpdated', serviceAddress);

      //Notify application that service address has been updated
      dispatch(
        serviceAddressActions.serviceAddressUpdatedWebsocket({
          ...serviceAddress
          //Checklist: checklist
        })
      );

      dispatch(webSocketActions.pageLoading(false));
    });

    hub.on('serviceAddressOffersUpdated', function (serviceAddress) {
      console.log('serviceAddressOffersUpdated', serviceAddress);

      //Notify application that service address offers have been updated (the reducer checks if they changed)
      dispatch(
        serviceAddressActions.serviceAddressOffersUpdated({
          ...serviceAddress,
          offersUpdated: new Date()
        })
      );
    });

    hub.on('serviceAddressOfferExtractionComplete', function (apiID) {
      console.log('serviceAddressOfferExtractionComplete', apiID);

      //Notify application that service address offers have been updated (the reducer checks if they changed)
      dispatch(
        serviceAddressActions.serviceAddressOfferExtractionComplete(apiID)
      );
    });

    hub.on('cartUpdated', function (cart) {
      console.log('cartUpdated', cart);

      //Notify application that service address has been updated
      dispatch(
        serviceAddressActions.cartUpdated(
          cart
            ? cart.map((cartItem) => {
                return { ...cartItem, fromWebSocket: true };
              })
            : []
        )
      );
    });

    hub.on('checklistUpdated', function (notification) {
      console.log('checklistUpdated', notification);

      //Notify application that service address has been updated
      dispatch(
        serviceAddressActions.checklistUpdated(
          notification.ServiceAddress,
          notification.Checklist
        )
      );
    });

    hub.on('systemTaskLog', function (message) {
      console.log('***systemTaskLog', message);
    });

    hub.on('serviceAddressLoadingInProgress', function (message) {
      console.log('***serviceAddressLoadingInProgress');
      if (userAuthApi.pageRequiresServiceAddress()) {
        //dispatch(webSocketActions.pageLoading(true, 'Loading...'));
      }
    });

    hub.connection.stateChanged(stateChanged);

    hub.promise = hub.connect();

    ws = {
      hub: hub
    };
  }

  return (
    <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>
  );
};
