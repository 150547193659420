import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby-link';
import { globalHistory } from '@reach/router/lib/history';
import { bindActionCreators } from 'redux';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import TimelineIcon from '@mui/icons-material/Timeline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfoIcon from '@mui/icons-material/Info';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import ProviderLogo from './ProviderLogo';
import OrderButton from './OrderButton';
import DescriptionIcon from '@mui/icons-material/Description';

import * as offerCalc from '../../api/offerCalc';
import * as dataApi from '../../api/dataApi';

import UsageChart from './UsageChart';
import IFrameDialog from '../IFrameDialog';
import LaunchIcon from '@mui/icons-material/Launch';

function CreditText({ offer }) {
  const amount =
    offer.ProviderCredit_Tier1_Amount ||
    offer.ProviderCredit_Tier2_Amount ||
    offer.ProviderCredit_Tier3_Amount ||
    offer.ProviderCredit_Tier4_Amount ||
    offer.ProviderCredit_Tier5_Amount ||
    '';

  return amount && !isNaN(amount) ? (
    <div className="credit-text">${amount.toFixed(2)} Bill Credit - </div>
  ) : null;
}

function OfferRowElectric({
  key,
  offer,
  offerDictionary,
  setOfferDictionary,
  removeFromCart,
  bannerText,
  buttonsComponent,
  selectedUsage,
  estimatedUsage,
  usageHistory,
  usageSetting,
  serviceAddress,
  setShowUsageHistoryPrompt,
  setShowUsageHistoryRequest,
  condensed,
  lastBillAmount,
  lastBillUsage,
  isFeatured,
  highlightPriceColumn,
  showDetails,
  closeFunc,
  tooltips,
  truePriceClicked
}) {
  const [rowOffer, setRowOffer] = useState(offer);
  const [rowSelectedUsage, setRowSelectedUsage] = useState(selectedUsage);
  const [expandedDetails, setExpandedDetails] = useState({
    selectedChart: 'trap'
  });
  const updateOfferTimeout = useRef();
  const [showFile, setShowFile] = useState(null);
  const [electricOfferAverageComparison, setElectricOfferAverageComparison] =
    useState(null);

  if (showDetails) {
    offerDictionary = { [offer.OfferID]: { expanded: true } };
  }

  useEffect(() => {
    if (updateOfferTimeout.current) {
      clearTimeout(updateOfferTimeout.current);
    }

    updateOfferTimeout.current = setTimeout(() => {
      if (rowSelectedUsage) {
        if (!offer.fullCostDetailApplied) {
          let updatedOffer = offerCalc.getFullCostDetail(
            offer,
            rowSelectedUsage,
            serviceAddress,
            usageHistory,
            usageSetting
          );

          if (updatedOffer.RateType) {
            updatedOffer.RateType = offerCalc.getRateType(offer);
          }

          setRowOffer(updatedOffer);
        }
      }
    }, 0);
  }, [rowSelectedUsage, offer]);

  useEffect(() => {
    if (!rowSelectedUsage) {
      setRowSelectedUsage(offerCalc.getSelectedUsage());
    }
  }, []);

  useEffect(() => {
    if (
      expandedDetails &&
      rowOffer.fullCostDetailApplied &&
      rowOffer.fullCostDetailApplied !== expandedDetails.selectedUsage
    ) {
      if (
        expandedDetails.selectedUsage === 500 ||
        expandedDetails.selectedUsage === 1000 ||
        expandedDetails.selectedUsage === 2000 ||
        expandedDetails.selectedUsage === estimatedUsage
      ) {
        //Need to update data needed for chart

        setRowOffer(
          offerCalc.getTrueCostDetail(
            rowOffer,
            expandedDetails.selectedUsage,
            serviceAddress,
            usageHistory,
            usageSetting
          )
        );
      }
    }
  }, [expandedDetails]);

  useEffect(() => {
    if (selectedUsage) {
      setRowSelectedUsage(selectedUsage);

      updateExpandedDetails();
    }
  }, [selectedUsage, rowSelectedUsage]);

  useEffect(() => {
    if (offer) {
      setRowOffer(offer);
    }
  }, [offer]);

  useEffect(() => {
    if (showDetails) {
      loadAverageComparison();
    }
  }, [showDetails]);

  useEffect(() => {
    const route = dataApi.getCurrentRoute();

    if (showFile) {
      navigate(route + '?viewFile');
    }
  }, [showFile]);

  useEffect(() => {
    return globalHistory.listen((location) => {
      if (location.action === 'PUSH') {
      }
      if (location.action === 'POP') {
        const route = dataApi.getCurrentRoute();
        setShowFile(null);
        navigate(route);
      }
    });
  });

  function updateExpandedDetails() {
    setExpandedDetails({
      ...expandedDetails,
      calcAmounts: {
        per500: offerCalc.calcElectricOffer(offer, 500),
        per1000: offerCalc.calcElectricOffer(offer, 1000),
        per2000: offerCalc.calcElectricOffer(offer, 2000),
        estimated: offerCalc.calcElectricOffer(offer, selectedUsage),
        selected: offerCalc.calcElectricOffer(offer, rowSelectedUsage)
      },
      selectedUsage: selectedUsage
    });
  }

  function getBreakdown() {
    let chargeBreakdown = [];
    let grandTotal = 0;
    if (expandedDetails.selectedUsageOverride) {
      chargeBreakdown = offerCalc.calcElectricOffer(
        offer,
        expandedDetails.selectedUsageOverride
      ).detailList;
    } else {
      chargeBreakdown = expandedDetails.calcAmounts.selected.detailList;
    }

    let currentGroup = null;
    let breakdown = [];
    chargeBreakdown.map((item) => {
      if (!currentGroup || item.category !== currentGroup.category) {
        if (currentGroup) {
          breakdown.push(currentGroup);
        }

        currentGroup = {
          category: item.category,
          subtotal: item.amount,
          itemList: [item]
        };
      } else {
        currentGroup.subtotal += item.amount;
        currentGroup.itemList.push(item);
      }

      grandTotal += item.amount;
    });

    if (currentGroup) {
      breakdown.push(currentGroup);
    }

    breakdown.push({
      grandTotal: true,
      amount: grandTotal
    });

    return breakdown;
  }

  function loadAverageComparison() {
    dataApi
      .listElectricOfferAverageComparison(rowOffer.OfferID)
      .then((_electricOfferAverageComparison) =>
        setElectricOfferAverageComparison(_electricOfferAverageComparison)
      );
  }

  return (
    <>
      <div
        className={
          'offer-row' +
          (offerDictionary &&
          offerDictionary[rowOffer.OfferID] &&
          offerDictionary[rowOffer.OfferID].expanded
            ? ' expanded'
            : '') +
          (rowOffer.FeatureBadge1 ? ' has-badges' : '') +
          (removeFromCart !== undefined ? ' no-buttons' : '') +
          (condensed !== undefined ? ' condensed' : '') +
          (isFeatured !== undefined ? ' featured' : '')
        }
        id={'offer' + offer.OfferID + (isFeatured ? '-a' : '')}
        onClick={() => {
          if (setOfferDictionary) {
            if (
              !offerDictionary ||
              !offerDictionary[rowOffer.OfferID] ||
              !offerDictionary[rowOffer.OfferID].expanded
            )
              setOfferDictionary({
                ...offerDictionary,
                [rowOffer.OfferID]: offerDictionary[rowOffer.OfferID]
                  ? {
                      ...offerDictionary[rowOffer.OfferID],
                      expanded: !offerDictionary[rowOffer.OfferID].expanded
                    }
                  : { expanded: true }
              });
          }
        }}>
        {bannerText ? <h2>{bannerText}</h2> : null}
        <div className="brand">
          <ProviderLogo offer={offer} />
        </div>
        <div className="primary-data">
          <div className="data-point term-length">
            <div className="content">
              <div className="title">
                {rowOffer.RateType ? rowOffer.RateType : ''} Rate
              </div>
              <div className="data">
                {rowOffer.TermLength ? rowOffer.TermLength : '1'}
                {/* {rowOffer.RateType === 'Tiered' ? (
                  <AccountTreeIcon />
                ) : rowOffer.RateType === 'Time of Use' ? (
                  <QueryBuilderIcon />
                ) : (
                  <LockIcon />
                )} */}
              </div>
              <div className="unit">
                {rowOffer.TermLength > 1 ? 'Month Term' : 'Monthly'}
              </div>
            </div>
          </div>

          {usageSetting === 'lastBill' &&
          rowOffer.SingleMonthPriceDollars &&
          rowOffer.AmountSaved !== null &&
          lastBillAmount ? (
            <>
              <div
                className={
                  'data-point price' +
                  (highlightPriceColumn === 'PriceKWh' ? ' highlighted' : '')
                }>
                <div className="content">
                  <div className="title">You paid</div>
                  <div className="data">
                    <span className="sup">$</span>
                    {lastBillAmount.toFixed(2).split('.')[0]}
                    <span className="sup">
                      {lastBillAmount.toFixed(2).split('.')[1]}
                    </span>
                  </div>
                  <div className="unit">
                    {lastBillUsage ? (
                      <>for {lastBillUsage} kWh</>
                    ) : (
                      <>per month</>
                    )}
                  </div>
                </div>
              </div>

              <div
                className={
                  'data-point price ' +
                  (highlightPriceColumn === 'SingleMonthPriceDollars'
                    ? ' highlighted'
                    : '')
                }>
                <div className="content">
                  <div className="title">Could have paid</div>
                  <div className="data">
                    <span className="sup">$</span>
                    {rowOffer.SingleMonthPriceDollars.toFixed(2).split('.')[0]}
                    <span className="sup">
                      {
                        rowOffer.SingleMonthPriceDollars.toFixed(2).split(
                          '.'
                        )[1]
                      }
                    </span>
                  </div>
                  <div className="unit">
                    {lastBillUsage ? (
                      <>for {lastBillUsage} kWh</>
                    ) : (
                      <>per month</>
                    )}
                  </div>
                </div>
              </div>

              <div
                className={
                  'data-point price' +
                  (highlightPriceColumn === 'AmountSaved' ? ' highlighted' : '')
                }>
                <div className="content">
                  <div className="title">
                    {rowOffer.PercentSaved.toFixed(0)}% less or
                  </div>
                  <div className="data">
                    <span className="sup">$</span>
                    {rowOffer.AmountSaved.toFixed(2).split('.')[0]}
                    <span className="sup">
                      {rowOffer.AmountSaved.toFixed(2).split('.')[1]}
                    </span>
                  </div>
                  <div className="unit dark">in savings</div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="data-point renewable">
                <div className="content">
                  <div className="title">% Renewable</div>
                  <div className="data">
                    {rowOffer.RenewableContent
                      ? parseFloat(rowOffer.RenewableContent).toFixed(0)
                      : '0'}
                    <span className="sup">%</span>
                  </div>
                  <div className="unit">Green Energy</div>
                </div>
              </div>

              {rowOffer.TrueCost_PerKWh ? (
                <div
                  className={
                    'data-point price true-price' +
                    (highlightPriceColumn === 'TrueCost_PerKWh'
                      ? ' highlighted'
                      : '')
                  }>
                  {truePriceClicked ? (
                    <div className="content">
                      <div className="title">
                        Avg of {rowOffer.TrueCost_Usage} kWh{' '}
                        {/* <Tooltip title={tooltips.TruePrice} color="primary">
                        <InfoIcon className="tooltip" />
                      </Tooltip> */}
                      </div>
                      <div className="data">
                        {parseFloat(rowOffer.TrueCost_PerKWh).toFixed(1)}
                        <span className="sup">¢</span>
                      </div>
                      <div className="unit">per kWh</div>
                    </div>
                  ) : null}
                </div>
              ) : null}

              {rowOffer.SingleMonthPriceDollars ? (
                <div
                  className={
                    'data-point price estimated-bill' +
                    (highlightPriceColumn === 'SingleMonthPriceDollars'
                      ? ' highlighted'
                      : '')
                  }>
                  <div className="content">
                    <div className="title">Estimated Bill</div>
                    <div className="data">
                      <span className="sup">$</span>
                      {parseFloat(rowOffer.SingleMonthPriceDollars).toFixed(2)}
                    </div>
                    <div className="unit">
                      at {rowOffer.SingleMonthPriceUsage} kWh
                    </div>
                  </div>
                </div>
              ) : null}

              <div
                className={
                  'data-point price advertised' +
                  (highlightPriceColumn === 'PriceKWh' ? ' highlighted' : '')
                }>
                <div className="content">
                  <div className="title">
                    <span className="not-mobile">
                      Exactly {rowOffer.SingleMonthPriceUsage} kWh
                    </span>
                    <span className="mobile-only inline">Average Price</span>{' '}
                    {/* <Tooltip
                      title="The advertised price is the average price listed on the Electric Facts Label (EFL) by the provider. It can be misleading because it assumes you'll use the same amount of energy each month without considering how your usage actually varies seasonally."
                      color="primary">
                      <InfoIcon className="tooltip" />
                    </Tooltip> */}
                  </div>
                  <div className="data">
                    {parseFloat(rowOffer.SingleMonthPriceKWh).toFixed(1)}
                    <span className="sup">¢</span>
                  </div>
                  <div className="unit not-mobile">per kWh</div>
                  <div className="mobile-only unit">
                    at {rowOffer.SingleMonthPriceUsage} kWh
                  </div>
                </div>
              </div>

              {/* {rowOffer.TrueCost_Dollars ? (
                <div className="data-point price">
                  <div className="content">
                    <div className="title">Avg bill</div>
                    <div className="data">
                      <span className="sup">$</span>
                      {rowOffer.TrueCost_Dollars.toFixed(2).split('.')[0]}
                      <span className="sup">
                        {rowOffer.TrueCost_Dollars.toFixed(2).split('.')[1]}
                      </span>
                    </div>
                    <div className="unit">per month</div>
                  </div>
                </div>
              ) : null} */}
            </>
          )}
        </div>

        {removeFromCart === undefined ? (
          <div className="action">
            <div className="data-point price highlighted">
              {/* <div className="content">
                <div className="title">Estimated Bill</div>
                <div className="data">
                  <span className="sup">$</span>
                  {highlightPriceColumn === 'PriceKWh'
                    ? parseFloat(rowOffer.SingleMonthPriceDollars).toFixed(2)
                    : parseFloat(rowOffer.TrueCost_Dollars).toFixed(2)}
                </div>
                <div className="unit">per Month</div>
              </div> */}
              <div className="content">
                <div className="title">
                  <span className="not-mobile">
                    Exactly {rowOffer.SingleMonthPriceUsage} kWh
                  </span>
                  <span className="mobile-only inline">Average Price</span>{' '}
                  {/* <Tooltip
                      title="The advertised price is the average price listed on the Electric Facts Label (EFL) by the provider. It can be misleading because it assumes you'll use the same amount of energy each month without considering how your usage actually varies seasonally."
                      color="primary">
                      <InfoIcon className="tooltip" />
                    </Tooltip> */}
                </div>
                <div className="data">
                  {parseFloat(rowOffer.SingleMonthPriceKWh).toFixed(1)}
                  <span className="sup">¢</span>
                </div>
                <div className="unit not-mobile">per kWh</div>
                <div className="mobile-only unit">
                  at {rowOffer.SingleMonthPriceUsage} kWh
                </div>
              </div>
            </div>
            <OrderButton offer={offer} />
          </div>
        ) : null}

        <div className="middle">
          <ProviderLogo offer={offer} />

          <div className="secondary-order-buttons">
            {removeFromCart === undefined && !buttonsComponent ? (
              <OrderButton offer={offer} secondary />
            ) : buttonsComponent ? (
              buttonsComponent
            ) : null}
          </div>
        </div>

        <div className="footer">
          <div className="offer-name">{rowOffer.DisplayName} </div>
          <div className="badges">
            {rowOffer.FeatureBadge1 ? (
              <div className="badge">{rowOffer.FeatureBadge1}</div>
            ) : null}
          </div>

          <div className="details-toggle">
            {!showDetails ? (
              <Button
                onClick={() => {
                  if (setOfferDictionary) {
                    if (
                      offerDictionary &&
                      offerDictionary[rowOffer.OfferID] &&
                      offerDictionary[rowOffer.OfferID].expanded
                    )
                      setOfferDictionary({
                        ...offerDictionary,
                        [rowOffer.OfferID]: offerDictionary[rowOffer.OfferID]
                          ? {
                              ...offerDictionary[rowOffer.OfferID],
                              expanded:
                                !offerDictionary[rowOffer.OfferID].expanded
                            }
                          : { expanded: true }
                      });

                    if (!electricOfferAverageComparison) {
                      loadAverageComparison();
                    }
                  }
                }}>
                Plan Details <ExpandMoreIcon />
              </Button>
            ) : null}
          </div>
          <CreditText offer={offer} />
          <div className="state-average-compare">
            {/* removed the following for now
             {rowOffer.CompareStateAverage_Count > 1 ? (
              <div className="multi-line">
                This plan offers below average pricing at multiple usage levels.
                <br />
                See plan details.
              </div>
            ) : rowOffer.CompareStateAverage_Start !== null ||
              rowOffer.CompareStateAverage_End !== null ? (
              <>
                This plan offers below average pricing{' '}
                {rowOffer.CompareStateAverage_End === 5000 ? (
                  rowOffer.CompareStateAverage_Start < 1000 ? (
                    <strong>for any usage amount</strong>
                  ) : (
                    <strong>
                      {' '}
                      above {rowOffer.CompareStateAverage_Start} kWh
                    </strong>
                  )
                ) : (
                  <>
                    if usage between{' '}
                    <strong>
                      {rowOffer.CompareStateAverage_Start} –{' '}
                      {rowOffer.CompareStateAverage_End} kWh
                    </strong>
                  </>
                )}
              </>
            ) : null} */}
          </div>
          <div className="spacer"></div>
        </div>

        <div className="offer-details">
          {(offerDictionary &&
            offerDictionary[rowOffer.OfferID] &&
            offerDictionary[rowOffer.OfferID].detailList) ||
          showDetails ? (
            <div className="container">
              <div className="top-details">
                {rowOffer.SummaryText ? (
                  <h5
                    className="summary-text"
                    dangerouslySetInnerHTML={{
                      __html: rowOffer.SummaryText
                    }}></h5>
                ) : null}

                <div className="detail-group">
                  {rowOffer.TermLength
                    ? rowOffer.TermLength + '-month term'
                    : 'Month-to-month '}{' '}
                </div>

                {rowOffer.RateType ? (
                  <div className="detail-group">{rowOffer.RateType} rate</div>
                ) : null}

                {rowOffer.RenewableContent ? (
                  <div className="detail-group">
                    {offerCalc.round(rowOffer.RenewableContent, 0)}% renewable
                    energy
                  </div>
                ) : null}

                {rowOffer.calcs && rowOffer.calcs.creditList
                  ? rowOffer.calcs.creditList.map((credit) => (
                      <div className="detail-group credit">
                        {credit.rateLong ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: credit.rateLong
                            }}></span>
                        ) : (
                          credit.rate
                        )}
                        {credit.isTiered ? (
                          credit.amount === 0 ? (
                            <span className="off">
                              <SentimentVeryDissatisfiedIcon />
                              Does not apply to {rowSelectedUsage} kWh
                            </span>
                          ) : (
                            <span className="on">
                              <CheckCircleIcon />
                              Applies to {rowSelectedUsage} kWh
                            </span>
                          )
                        ) : null}
                      </div>
                    ))
                  : null}

                {rowOffer.OfferDetailText ? (
                  <div className="detail-group">{rowOffer.OfferDetailText}</div>
                ) : null}

                {rowOffer.TerminationFeeAmount ? (
                  <div className="detail-group">
                    $
                    {rowOffer.TerminationFeeAmount
                      ? rowOffer.TerminationFeeAmount.toFixed(0)
                      : '0'}{' '}
                    {rowOffer.TerminationFeeTypeID == 2
                      ? ' per month remaining in contract '
                      : ''}{' '}
                    Early Termination Fee{' '}
                    {rowOffer.TerminationFeeAmount ? (
                      <span>
                        - but if you move to a new home, this fee won't apply
                      </span>
                    ) : null}
                    .
                  </div>
                ) : null}

                {rowOffer.SatisfactionGuaranteeText && (
                  <div className="detail-group">
                    {rowOffer.SatisfactionGuaranteeText}
                  </div>
                )}

                <div className="provider-disclosures">
                  <Button
                    variant="text"
                    href={offerCalc.checkOffsiteLink(offer, 'EFLUrl', 'href')}
                    target={offerCalc.checkOffsiteLink(
                      offer,
                      'EFLUrl',
                      'target'
                    )}
                    onClick={(event) => {
                      if (
                        offerCalc.checkOffsiteLink(offer, 'EFLUrl', 'click')
                      ) {
                        setShowFile({
                          title: offer.ProviderName + ' - Electric Facts Label',
                          url: offer.EFLUrl
                        });
                      }
                      event.stopPropagation();
                    }}>
                    <LaunchIcon /> Electricity Facts Label (EFL)
                  </Button>
                  <Button
                    variant="text"
                    href={offerCalc.checkOffsiteLink(offer, 'TOSUrl', 'href')}
                    target={offerCalc.checkOffsiteLink(
                      offer,
                      'TOSUrl',
                      'target'
                    )}
                    onClick={(event) => {
                      if (
                        offerCalc.checkOffsiteLink(offer, 'TOSUrl', 'click')
                      ) {
                        setShowFile({
                          title: offer.ProviderName + ' - Terms of Service',
                          url: offer.TOSUrl
                        });
                      }
                      event.stopPropagation();
                    }}>
                    <LaunchIcon /> Terms of Service (TOS)
                  </Button>
                  <Button
                    variant="text"
                    href={offerCalc.checkOffsiteLink(offer, 'YRAACUrl', 'href')}
                    target={offerCalc.checkOffsiteLink(
                      offer,
                      'YRAACUrl',
                      'target'
                    )}
                    onClick={(event) => {
                      if (
                        offerCalc.checkOffsiteLink(offer, 'YRAACUrl', 'click')
                      ) {
                        setShowFile({
                          title:
                            offer.ProviderName + ' - Your Rights as a Customer',
                          url: offer.YRAACUrl
                        });
                      }
                      event.stopPropagation();
                    }}>
                    <LaunchIcon />
                    Your Rights as a Constomer (YRAC)
                  </Button>
                </div>
              </div>

              {expandedDetails ? (
                <UsageChart
                  key={rowOffer.OfferID}
                  selectedUsage={expandedDetails.selectedUsage}
                  usageHistory={usageHistory}
                  offer={rowOffer}
                  usageSetting={usageSetting}
                  expandedDetails={expandedDetails}
                  setExpandedDetails={setExpandedDetails}
                  setShowUsageHistoryPrompt={setShowUsageHistoryPrompt}
                  setShowUsageHistoryRequest={setShowUsageHistoryRequest}
                  electricOfferAverageComparison={
                    electricOfferAverageComparison
                  }
                  desktop
                />
              ) : null}

              {expandedDetails && expandedDetails.calcAmounts ? (
                <>
                  <div className="offer-section prices">
                    <h5>
                      <span>
                        <span>Advertised Prices</span>
                      </span>
                    </h5>
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Usage</TableCell>
                            <TableCell>Price per kWh</TableCell>
                            <TableCell>Monthly Total</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            className={
                              expandedDetails.selectedUsage === 500
                                ? 'selected'
                                : ''
                            }
                            onClick={(event) => {
                              setExpandedDetails({
                                ...expandedDetails,
                                selectedUsage: 500,
                                selectedUsageOverride: 500
                              });
                              event.preventDefault();
                              event.stopPropagation();
                            }}>
                            <TableCell>500 kWh</TableCell>
                            <TableCell>
                              {offerCalc
                                .round(
                                  expandedDetails.calcAmounts.per500.perKWh *
                                    100,
                                  1
                                )
                                .toFixed(1)}
                              ¢
                            </TableCell>
                            <TableCell>
                              $
                              {offerCalc
                                .round(
                                  expandedDetails.calcAmounts.per500.dollars,
                                  2
                                )
                                .toFixed(2)}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            className={
                              expandedDetails.selectedUsage === 1000
                                ? 'selected'
                                : ''
                            }
                            onClick={(event) => {
                              setExpandedDetails({
                                ...expandedDetails,
                                selectedUsage: 1000,
                                selectedUsageOverride: 1000
                              });
                              event.preventDefault();
                              event.stopPropagation();
                            }}>
                            <TableCell>1000 kWh</TableCell>
                            <TableCell>
                              {offerCalc
                                .round(
                                  expandedDetails.calcAmounts.per1000.perKWh *
                                    100,
                                  1
                                )
                                .toFixed(1)}
                              ¢
                            </TableCell>
                            <TableCell>
                              $
                              {offerCalc
                                .round(
                                  expandedDetails.calcAmounts.per1000.dollars,
                                  2
                                )
                                .toFixed(2)}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            className={
                              expandedDetails.selectedUsage === 2000
                                ? 'selected'
                                : ''
                            }
                            onClick={(event) => {
                              setExpandedDetails({
                                ...expandedDetails,
                                selectedUsage: 2000,
                                selectedUsageOverride: 2000
                              });
                              event.preventDefault();
                              event.stopPropagation();
                            }}>
                            <TableCell>2000 kWh</TableCell>
                            <TableCell>
                              {offerCalc
                                .round(
                                  expandedDetails.calcAmounts.per2000.perKWh *
                                    100,
                                  1
                                )
                                .toFixed(1)}
                              ¢
                            </TableCell>
                            <TableCell>
                              $
                              {offerCalc
                                .round(
                                  expandedDetails.calcAmounts.per2000.dollars,
                                  2
                                )
                                .toFixed(2)}
                            </TableCell>
                          </TableRow>
                          {selectedUsage &&
                          selectedUsage !== 500 &&
                          selectedUsage !== 1000 &&
                          selectedUsage !== 2000 ? (
                            <TableRow
                              className={
                                expandedDetails.selectedUsage === selectedUsage
                                  ? 'selected'
                                  : ''
                              }
                              onClick={(event) => {
                                setExpandedDetails({
                                  ...expandedDetails,
                                  selectedUsage: selectedUsage,
                                  selectedUsageOverride: null
                                });
                                event.preventDefault();
                                event.stopPropagation();
                              }}>
                              <TableCell>{selectedUsage} kWh</TableCell>
                              <TableCell>
                                {offerCalc.round(
                                  expandedDetails.calcAmounts.estimated.perKWh *
                                    100,
                                  1
                                )}
                                ¢
                              </TableCell>
                              <TableCell>
                                $
                                {offerCalc.round(
                                  expandedDetails.calcAmounts.estimated.dollars,
                                  2
                                )}
                              </TableCell>
                            </TableRow>
                          ) : null}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <div className="offer-section breakdown">
                    <h5>
                      <span>
                        <span>
                          Breakdown of Charges for{' '}
                          {expandedDetails.selectedUsageOverride
                            ? expandedDetails.selectedUsageOverride
                            : expandedDetails.selectedUsage}{' '}
                          kWh
                        </span>
                      </span>
                    </h5>
                    <TableContainer style={{ overflowX: 'hidden' }}>
                      <Table aria-label="simple table">
                        {getBreakdown().map((itemGroup) =>
                          itemGroup.grandTotal ? (
                            <TableHead>
                              <TableRow className="footer">
                                <TableCell>
                                  One-month Estimate for{' '}
                                  {expandedDetails.selectedUsageOverride
                                    ? expandedDetails.selectedUsageOverride
                                    : expandedDetails.selectedUsage}{' '}
                                  kWh:
                                </TableCell>
                                <TableCell>
                                  {offerCalc.formatDollars(itemGroup.amount)}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                          ) : (
                            <>
                              <TableHead>
                                <TableRow>
                                  <TableCell colSpan="2">
                                    {itemGroup.category === 'TDU Charge'
                                      ? 'TDU Delivery Charges (' +
                                        offer.UtilityName +
                                        ')'
                                      : itemGroup.category === 'Provider Charge'
                                      ? 'Provider Charges (' +
                                        offer.ProviderName +
                                        ')'
                                      : itemGroup.category}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {itemGroup.itemList.map((item) => (
                                  <TableRow
                                    className={
                                      item.isBillCredit
                                        ? item.amount
                                          ? 'credit'
                                          : 'no-credit'
                                        : ''
                                    }>
                                    <TableCell
                                      colSpan={
                                        item.description !== 'Usage Discount'
                                          ? 1
                                          : 2
                                      }>
                                      {item.description ? (
                                        <>
                                          <strong>{item.description}:</strong>{' '}
                                        </>
                                      ) : null}
                                      <span
                                        className={
                                          item.rate && item.rate.length < 50
                                            ? 'no-break'
                                            : ''
                                        }>
                                        {item.rate}
                                      </span>
                                    </TableCell>
                                    {item.description !== 'Usage Discount' ? (
                                      <TableCell>
                                        {item.description !== 'Usage Discount'
                                          ? offerCalc.formatDollars(item.amount)
                                          : ''}
                                      </TableCell>
                                    ) : null}
                                  </TableRow>
                                ))}
                                <TableRow className="footer">
                                  <TableCell>
                                    Sub-Total{' '}
                                    {itemGroup.category === 'TDU Charge'
                                      ? 'TDU Charges'
                                      : 'Provider Charges'}
                                    :
                                  </TableCell>
                                  <TableCell>
                                    {offerCalc.formatDollars(
                                      itemGroup.subtotal
                                    )}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </>
                          )
                        )}
                      </Table>
                    </TableContainer>
                  </div>

                  <UsageChart
                    selectedUsage={expandedDetails.selectedUsage}
                    usageHistory={usageHistory}
                    offer={rowOffer}
                    expandedDetails={expandedDetails}
                    usageSetting={usageSetting}
                    setExpandedDetails={setExpandedDetails}
                    setShowUsageHistoryPrompt={setShowUsageHistoryPrompt}
                    setShowUsageHistoryRequest={setShowUsageHistoryRequest}
                    electricOfferAverageComparison={
                      electricOfferAverageComparison
                    }
                  />

                  <div className="offer-section notice">
                    These estimated costs include all recurring charges you'll
                    see on your bill, excluding government fees and taxes. You
                    should confirm this information using the Electricity Facts
                    Label (EFL) and Terms of Service (TOS). Your actual costs
                    will vary depending on actual usage.
                  </div>
                </>
              ) : null}
            </div>
          ) : (
            <LinearProgress />
          )}

          <Button
            className="offer-details-secondary-close"
            fullWidth={true}
            onClick={() => {
              if (setOfferDictionary) {
                if (
                  offerDictionary &&
                  offerDictionary[rowOffer.OfferID] &&
                  offerDictionary[rowOffer.OfferID].expanded
                ) {
                  const row = document.getElementById('offer' + offer.OfferID);
                  if (row) {
                    dataApi.scrollToElementInPage(row, -190, false);
                  }

                  setOfferDictionary({
                    ...offerDictionary,
                    [rowOffer.OfferID]: offerDictionary[rowOffer.OfferID]
                      ? {
                          ...offerDictionary[rowOffer.OfferID],
                          expanded: !offerDictionary[rowOffer.OfferID].expanded
                        }
                      : { expanded: true }
                  });
                }
              } else if (closeFunc) {
                closeFunc();
              }
            }}
            variant="outlined">
            Hide Plan Details
          </Button>
        </div>
      </div>
      {showFile ? (
        <IFrameDialog
          title={showFile.title}
          url={showFile.url}
          closeFunc={() => setShowFile(null)}
        />
      ) : null}
    </>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(OfferRowElectric);
