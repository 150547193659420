import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby';

import clsx from 'clsx';

import { IconButton, Box } from '@mui/material';
import { navigate } from 'gatsby';

import { getCurrentRoute, isExcludeRoute } from '../../api/dataApi';

function HeaderLogo({ siteConfig, serviceAddress, route }) {
  const [cobrandMessage, setCobrandMessage] = useState(null);

  useEffect(() => {
    let _cobrandMessage = '';

    if (
      siteConfig.id == 'mi' &&
      (!isExcludeRoute(route) || route.indexOf('/demo') === 0) &&
      serviceAddress &&
      serviceAddress.Address &&
      serviceAddress.Address.PropertyName &&
      serviceAddress.Address.PropertyStatusID != 7
    ) {
      _cobrandMessage = (
        <>
          <span className="property-name">
            {serviceAddress.Address.PropertyName}
          </span>
          {siteConfig.brand} Dashboard
        </>
      );
    }

    setCobrandMessage(_cobrandMessage);
  }, [serviceAddress, route]);

  function logoClicked() {
    switch (siteConfig.id) {
      case 'sc':
        // if (window && window.location.href.indexOf('checkout') !== -1) {
        //   navigate('/electric-rates');
        // } else {
        //   navigate('/');
        // }

        if (route == '/') {
          window.location = '/?zipEntered=0';
        } else {
          navigate('/');
        }
        break;
      default:
        if (
          route &&
          route.indexOf('/property-') === -1 &&
          route.indexOf('/company-') === -1
        ) {
          //if (window && window.location.href.indexOf('dashboard') === -1) {
          navigate('/dashboard');
          // } else {
          //   navigate('/');
          // }
        }
        break;
    }
  }

  return (
    <Fragment>
      {cobrandMessage ? (
        <div className="app-header-logo cobrand">
          <a onClick={() => logoClicked()}>{cobrandMessage}</a>
        </div>
      ) : (
        <div className={clsx('app-header-logo', {})}>
          <a onClick={() => logoClicked()}>
            <img
              style={{
                maxWidth: siteConfig.logoMaxWidth
              }}
              className="app-header-logo-img"
              alt={siteConfig.brand}
              src={withPrefix(siteConfig.logo)}
            />
          </a>
        </div>
      )}
    </Fragment>
  );
}

function mapStateToProps(state) {
  return {
    siteConfig: state.commonData.siteConfig,
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {}
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLogo);
