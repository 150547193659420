import React, { useEffect, useRef, useState } from 'react';
import Loadable from '@loadable/component';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Skeleton from '@mui/material/Skeleton';

import ConnectSmartMeterButton from './ConnectSmartMeterButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import * as dataApi from '../../api/dataApi';
import * as offerCalc from '../../api/offerCalc';

const summaryRow = dataApi.getMonthsOfYear();

const _chartOptions = {
  chart: {
    id: 'basic-bar',
    toolbar: {
      show: false
    },
    zoom: {
      enabled: false
    }
  },
  // plotOptions: {
  //   bar: {
  //     //columnWidth: '50%',
  //     strokeWidth: 1,
  //     endingShape: 'arrow'
  //   }
  // },
  stroke: {
    width: [1, 0]
    //dashArray: [5, 0]
  },

  colors: [
    '#ff3366',
    function (params) {
      if (params && params.w && params.w.config && params.w.config.series) {
        const series = params.w.config.series[params.seriesIndex];
        if (series && series.data) {
          const data = series.data[params.dataPointIndex];
          if (data) {
            switch (data.type) {
              case 'average':
                return '#ff3366'; //Average
              case 'projected':
                return '#8effd8'; //Projected
              default:
                return '#26e7a6'; //Actual / Estimated
            }
          }
        }
      }
      return '#999999';
    }
  ],

  xaxis: {
    categories: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]
  },
  markers: {
    size: 0,
    strokeWidth: 3,
    fillOpacity: 0,
    strokeOpacity: 0,
    hover: {
      size: 8
    }
  },
  yaxis: {
    tickAmount: 5,
    min: 0,
    max: 0,
    showForNullSeries: false
  }
};

function usageFormatter(value, params) {
  let formattedValue = offerCalc.round(value, 0);

  if (params && params.w && params.w.config && params.w.config.series) {
    const series = params.w.config.series[params.seriesIndex];
    if (series && series.data) {
      const data = series.data[params.dataPointIndex];
      if (data) {
        switch (data.type) {
          case 'projected':
            formattedValue += ' (Projected)';
            break;
          case 'actual':
            formattedValue += ' (Actual)';
            break;
          case 'estimated':
            formattedValue += ' (Estimated)';
            break;
          default:
            break;
        }
      }
    }
  }

  return formattedValue;
}

function dollarsFormatter(value) {
  return '$' + offerCalc.round(value, 2);
}

function centsFormatter(value) {
  return offerCalc.round(value, 1).toFixed(1) + '¢';
}

const _dontBeAverageChartOptions = {
  chart: {
    toolbar: {
      show: false
    },
    zoom: {
      enabled: false
    }
  },

  stroke: {
    width: [2, 3],
    curve: 'smooth',
    dashArray: [5, 0]
  },
  colors: ['#ff3366', '#337eff'],

  xaxis: {
    categories: [], //Todo - array of usage level,
    tickAmount: 24,
    type: 'numeric',
    min: 0,
    max: 2400,
    labels: {
      formatter: (value) => {
        return offerCalc.round(value, 1).toFixed(0) + ' kWh';
      }
    }
  },

  yaxis: {
    tickAmount: 10,
    min: 0,
    max: 30,

    labels: {
      formatter: (value) => {
        return offerCalc.round(value, 1).toFixed(1) + '¢';
      },
      show: true
    }
  }
};

function UsageChart({
  desktop,
  selectedUsage,
  usageHistory,
  serviceAddress,
  offer,
  usageSetting,
  expandedDetails,
  setExpandedDetails,
  setShowUsageHistoryPrompt,
  setShowUsageHistoryRequest,
  electricOfferAverageComparison
}) {
  const [selectedUsageSetting, setSelectedUsageSetting] = useState(
    usageSetting === 'actual' ? 'actual' : 'estimated'
  );
  const [dataExpanded, setDataExpanded] = useState(true);
  const [tableData, setTableData] = useState(null);
  const [dataSource, setDataSource] = useState(null);
  const [chartOptions, setChartOptions] = useState(null);
  const [chartData, setChartData] = useState(null);
  const chartTimeout = useRef();
  const chartApi = useRef();
  const chartOptionsTimeout = useRef();

  const [trapChartOptions, setTrapChartOptions] = useState({
    ..._dontBeAverageChartOptions
  });
  const [trapChartSeries, setTrapChartSeries] = useState(null);

  useEffect(() => {
    setSelectedUsageSetting(usageSetting === 'actual' ? 'actual' : 'estimated');
  }, [usageSetting]);

  useEffect(() => {
    updateChart();
  }, [selectedUsageSetting, expandedDetails]);

  useEffect(() => {
    if (chartTimeout.current) {
      clearTimeout(chartTimeout.current);
    }

    setChartOptions(null);
    chartTimeout.current = setTimeout(updateChart, 0);
  }, [offer]);

  useEffect(() => {
    console.log(
      'electricOfferAverageComparison',
      electricOfferAverageComparison
    );
    if (
      electricOfferAverageComparison &&
      electricOfferAverageComparison.length
    ) {
      const row = electricOfferAverageComparison[0];

      let lowUsage = null;
      let highUsage = null;
      let lowDollars = null;
      let highDollars = null;
      let lowPrice = null;
      let highPrice = null;
      let creditCount = 0;

      let _chartData = [];

      const _usageList = Object.keys(row)
        .filter((field) => {
          const usage = field.split('C').join('');
          if (!isNaN(usage)) {
            return true;
          }

          return false;
        })
        .map((usageField) => {
          const _usage = parseFloat(usageField.split('C').join(''));
          _chartData.push({
            usage: _usage,
            perKWh: offerCalc.round(row[usageField] * 100, 1),
            stateAverage: offerCalc.round(row.StateAveragePerKWH * 100, 1),
            advertisedPrice: offerCalc.round(offer.PriceAt1000 * 100, 1)
          });

          return _usage;
        });

      console.log('_chartData', _chartData);

      setTrapChartOptions({
        ...trapChartOptions,
        xaxis: { ...trapChartOptions.xaxis, categories: _usageList }
      });

      setTrapChartSeries([
        // {
        //   name: 'State Average',
        //   data: _chartData.map((_price) => _price.stateAverage),
        //   type: 'line'
        // },
        {
          name: 'Advertised Price',
          data: _chartData.map((_price) => _price.advertisedPrice),
          type: 'line'
        },
        {
          name: 'True Price',
          data: _chartData.map((_price) => _price.perKWh),
          type: 'line'
        }
      ]);
    }
  }, [electricOfferAverageComparison]);

  useEffect(() => {
    if (tableData) {
      let _lowUsage = null;
      let _highUsage = null;
      Object.keys(tableData).map((month) => {
        if (tableData[month] && tableData[month].usage) {
          if (_lowUsage === null || tableData[month].usage < _lowUsage) {
            _lowUsage = tableData[month].usage;
          }

          if (_highUsage === null || tableData[month].usage > _highUsage) {
            _highUsage = tableData[month].usage;
          }
        }
      });

      if (_lowUsage && _highUsage) {
        setTrapChartOptions({
          ...trapChartOptions,
          annotations: {
            xaxis: [
              {
                x: _lowUsage,
                x2: _highUsage

                // label: {
                //   text:
                //     'Low of ' +
                //     _lowUsage +
                //     ' kWh / High of ' +
                //     _highUsage +
                //     ' kWh',
                //   borderColor: 'transparent',
                //   orientation: 'horizontal',
                //   style: {
                //     background: 'transparent'
                //   },
                //   textAnchor: 'start',
                //   position: 'bottom',
                //   offsetY: -10
                // }
              }
            ]
          }
        });
      }
    }
  }, [tableData]);

  function updateChart() {
    try {
      let _tableData =
        selectedUsageSetting === 'estimated'
          ? offer.trueCost.estimated.table
          : offer.trueCost.actual.table;
      let _max = 0;

      const hasProjected =
        Object.keys(_tableData).map((month) => _tableData[month].isProjected)
          .length > 0;

      let _chartData = {
        name:
          expandedDetails.selectedChart === 'usage'
            ? 'Monthly Usage'
            : // selectedUsageSetting === 'estimated'
            //   ? 'Projected Usage'
            //   : hasProjected
            //   ? 'Actual / Projected Usage'
            //   : 'Actual Usage'
            expandedDetails.selectedChart === 'perKWh'
            ? 'Cost per kWh'
            : 'Monthly Total',
        type: 'bar',
        data: []
      };

      let _chartData_Average = {
        name:
          expandedDetails.selectedChart === 'usage'
            ? 'Average Usage'
            : expandedDetails.selectedChart === 'perKWh'
            ? 'Average Rate'
            : 'Average Cost',
        type: 'line',
        data: []
      };

      if (_tableData) {
        Object.keys(_tableData).map((month) => {
          if (
            !isNaN(_tableData[month][expandedDetails.selectedChart]) &&
            _tableData[month][expandedDetails.selectedChart] > _max
          ) {
            _max = parseFloat(_tableData[month][expandedDetails.selectedChart]);
          }
        });

        summaryRow.map((month) => {
          if (month !== 'Average') {
            if (
              _tableData[month] &&
              _tableData[month][expandedDetails.selectedChart] &&
              !isNaN(_tableData[month][expandedDetails.selectedChart])
            ) {
              _chartData.data.push({
                x: month,
                y: _tableData[month][expandedDetails.selectedChart],
                type:
                  selectedUsageSetting !== 'actual'
                    ? 'estimated'
                    : _tableData[month].isProjected
                    ? 'projected'
                    : 'actual'
              });
            } else {
              _chartData.data.push({ x: month, y: 0, type: 'projected' });
            }
          }
        });

        if (_tableData['Average']) {
          summaryRow.map((month) => {
            _chartData_Average.data.push({
              x: month,
              y: _tableData['Average'][expandedDetails.selectedChart],
              type: 'average'
            });
          });
        }
      }

      let newChartMax = _max;
      let tickDivisor = 1;

      switch (expandedDetails.selectedChart) {
        case 'usage':
          newChartMax = offerCalc.round(_max / 500, 0) * 500 + 500;
          tickDivisor = 500;
          break;
        case 'perKWh':
          newChartMax = offerCalc.round(_max / 5, 0) * 5 + 5;
          tickDivisor = 5;
          break;
        default:
          newChartMax = offerCalc.round(_max / 100, 0) * 100 + 100;
          tickDivisor = 100;
          break;
      }

      setTableData(_tableData);
      setChartData([_chartData_Average, _chartData]);

      setDataSource(
        selectedUsageSetting === 'estimated'
          ? offer.trueCost.estimated.source
          : offer.trueCost.actual.source
      );

      if (chartOptionsTimeout.current) {
        clearTimeout(chartOptionsTimeout.current);
      }

      //chartOptionsTimeout.current = setTimeout(() => {
      setChartOptions({
        ..._chartOptions,
        chart: {
          ..._chartOptions.chart,
          events: {
            markerClick: (
              event,
              chartContext,
              { seriesIndex, dataPointIndex, config }
            ) => {
              const clickedMonth = _tableData[summaryRow[dataPointIndex]];

              setExpandedDetails({
                ...expandedDetails,
                selectedUsageOverride: offerCalc.round(clickedMonth.usage, 0)
              });
            },
            mounted: function (chart) {
              chartApi.current = chart;
            }
          }
        },
        yaxis: {
          ..._chartOptions.yaxis,
          max: newChartMax,
          tickAmount: newChartMax / tickDivisor,
          labels: {
            formatter:
              expandedDetails.selectedChart === 'usage'
                ? usageFormatter
                : expandedDetails.selectedChart === 'perKWh'
                ? centsFormatter
                : dollarsFormatter
          }
        }
      });

      // }, 300);
    } catch (e) {}
  }

  return tableData && chartData ? (
    <>
      <div
        className={
          'chart ' +
          (desktop ? 'desktop' : 'mobile') +
          (dataExpanded ? ' data-expanded' : '')
        }>
        <div className="offer-section">
          <h5>
            <span>
              <span>
                {selectedUsageSetting === 'estimated' ? (
                  <>
                    Seasonally Adjusted Projections
                    <br />
                    Based on a 12-Month Average of {selectedUsage} kWh
                  </>
                ) : (
                  <>Price Based on Actual Usage History</>
                )}
              </span>
            </span>
          </h5>

          <div className="usage-mode-tabs">
            <Tabs
              value={selectedUsageSetting}
              onChange={(event, newValue) => {
                if (
                  newValue === 'actual' &&
                  selectedUsageSetting === 'actual'
                ) {
                  setShowUsageHistoryPrompt(true);
                }

                setSelectedUsageSetting(newValue);
              }}
              indicatorColor="primary"
              textColor="primary">
              <Tab label="Estimated Usage" value="estimated" />
              <Tab label="Actual Usage" value="actual" />
            </Tabs>
          </div>

          <div className="data-container">
            {tableData && !tableData.noHistory ? (
              <>
                <div className="chart-tabs">
                  <Tabs
                    value={expandedDetails.selectedChart}
                    variant={desktop ? 'standard' : 'fullWidth'}
                    onChange={(event, newValue) => {
                      setExpandedDetails({
                        ...expandedDetails,
                        selectedChart: newValue
                      });
                    }}
                    centered>
                    <Tab label="Rate Analysis" value="trap" />
                    <Tab label="Usage per Month" value="usage" />
                    <Tab label="Avg Price/kWh" value="perKWh" />
                    <Tab label="Cost per Month" value="dollars" />
                  </Tabs>
                </div>

                <div className="chart-container">
                  {expandedDetails.selectedChart === 'trap' ? (
                    trapChartSeries && trapChartOptions ? (
                      <>
                        <LoadableChart
                          key="trapChart"
                          options={trapChartOptions}
                          series={trapChartSeries}
                          type="line"
                          width="600px"
                          height="400px"
                          className="hide-mobile"
                        />

                        <LoadableChart
                          key="trapChart"
                          options={trapChartOptions}
                          series={trapChartSeries}
                          type="line"
                          width="320px"
                          height="350px"
                          className="mobile-only"
                        />
                      </>
                    ) : null
                  ) : chartOptions && chartData ? (
                    <LoadableChart
                      options={chartOptions}
                      series={chartData}
                      type="line"
                      height="350px"
                    />
                  ) : null}
                </div>

                <div className="data-detail">
                  Chart based on {dataSource}
                  {selectedUsageSetting === 'actual' ? (
                    <Button
                      variant="outlined"
                      className="enter-usage"
                      onClick={() => setShowUsageHistoryPrompt(true)}>
                      Edit Usage History
                    </Button>
                  ) : null}
                  <Button onClick={() => setDataExpanded(!dataExpanded)}>
                    {dataExpanded ? 'Hide' : 'Show'} data <ExpandMoreIcon />
                  </Button>
                  <div className="table">
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Month</TableCell>
                            <TableCell>Usage</TableCell>
                            <TableCell className="credit-applies">
                              Credit
                              <span className="hide-mobile"> Applies</span>
                            </TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Monthly Cost</TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {summaryRow.map((row, rowIndex) =>
                            tableData[row] ? (
                              <TableRow
                                className={
                                  expandedDetails.selectedUsageOverride ===
                                  tableData[row].usage
                                    ? 'selected'
                                    : ''
                                }
                                onClick={() => {
                                  if (chartApi.current) {
                                    chartApi.current.toggleDataPointSelection(
                                      1,
                                      rowIndex
                                    );
                                  }

                                  setExpandedDetails({
                                    ...expandedDetails,
                                    selectedUsageOverride: tableData[row].usage
                                  });
                                }}>
                                <TableCell>
                                  {row} {tableData[row].isProjected ? '*' : ''}
                                </TableCell>
                                <TableCell>
                                  {tableData[row] && tableData[row].usage
                                    ? tableData[row].usage
                                    : ''}
                                </TableCell>
                                <TableCell className="credit-applies">
                                  {tableData[row].creditApplies ? (
                                    <CheckCircleOutlineIcon />
                                  ) : null}
                                </TableCell>
                                <TableCell>
                                  {tableData[row] && tableData[row].perKWh
                                    ? offerCalc
                                        .round(tableData[row].perKWh, 1)
                                        .toFixed(1) + '¢'
                                    : ''}
                                </TableCell>
                                <TableCell>
                                  {tableData[row] && tableData[row].dollars
                                    ? '$' +
                                      offerCalc
                                        .round(tableData[row].dollars, 2)
                                        .toFixed(2)
                                    : ''}
                                </TableCell>
                              </TableRow>
                            ) : null
                          )}

                          <TableRow>
                            <TableCell>Average</TableCell>
                            <TableCell>
                              {tableData.Average && tableData.Average.usage
                                ? offerCalc.round(tableData.Average.usage, 0)
                                : ''}
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                              {tableData.Average && tableData.Average.perKWh
                                ? offerCalc
                                    .round(tableData.Average.perKWh, 1)
                                    .toFixed(1) + '¢'
                                : ''}
                            </TableCell>
                            <TableCell>
                              {tableData.Average && tableData.Average.dollars
                                ? '$' +
                                  offerCalc.round(tableData.Average.dollars, 2)
                                : ''}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>

                    {tableData.someProjected ? (
                      <p>
                        * Usage for months not entered is estimated based on the
                        data you have entered and the Seasonal Usage Profile for
                        Texas according to the Energy Information Administration
                        (EIA).
                      </p>
                    ) : null}
                  </div>
                </div>
              </>
            ) : (
              <div className="no-history">
                You haven't entered any usage history.
                <div className="buttons">
                  <ConnectSmartMeterButton
                    setShowUsageHistoryRequest={setShowUsageHistoryRequest}
                  />
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setShowUsageHistoryPrompt(true)}>
                    Enter Manually
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  ) : null;
}

function mapStateToProps(state) {
  return {
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(UsageChart);

const LoadableChart = Loadable(() => import('react-apexcharts'));
