import * as types from './actionTypes';

import * as residentOnboardingApi from '../../api/residentOnboardingApi';

export function residentOnboardingUpdated(residentOnboarding) {
  return { type: types.RESIDENT_ONBOARDING_UPDATED, residentOnboarding };
}

export function residentOnboardingHeaderUpdated(residentOnboarding) {
  return {
    type: types.RESIDENT_ONBOARDING_HEADER_UPDATED,
    header: { ...residentOnboarding.Header }
  };
}

export function residentOnboardingCleared() {
  return {
    type: types.RESIDENT_ONBOARDING_CLEARED
  };
}

export function residentOnboardingChecklistUpdated(checklist) {
  return {
    type: types.RESIDENT_ONBOARDING_CHECKLIST_UPDATED,
    checklist
  };
}

export function residentOnboardingProviderServiceUpdated(providerService) {
  return {
    type: types.RESIDENT_ONBOARDING_PROVIDERSERVICE_UPDATED,
    providerService
  };
}

export function residentOnboardingVehicleUpdated(vehicle) {
  return {
    type: types.RESIDENT_ONBOARDING_VEHICLE_UPDATED,
    vehicle
  };
}

export function residentOnboardingVehicleAdded(vehicle) {
  return {
    type: types.RESIDENT_ONBOARDING_VEHICLE_ADDED,
    vehicle
  };
}

export function residentOnboardingVehicleDeleted(vehicle) {
  return {
    type: types.RESIDENT_ONBOARDING_VEHICLE_DELETED,
    vehicle
  };
}

export function residentOnboardingPetUpdated(pet) {
  return {
    type: types.RESIDENT_ONBOARDING_PET_UPDATED,
    pet
  };
}

export function residentOnboardingPetAdded(pet) {
  return {
    type: types.RESIDENT_ONBOARDING_PET_ADDED,
    pet
  };
}

export function residentOnboardingPetDeleted(pet) {
  return {
    type: types.RESIDENT_ONBOARDING_PET_DELETED,
    pet
  };
}

export function residentFileListUpdated(fileList) {
  console.log('RESIDENT_ONBOARDING_FILELIST_UPDATED', fileList);
  return {
    type: types.RESIDENT_ONBOARDING_FILELIST_UPDATED,
    fileList
  };
}

export function loadResidentOnboarding(residentID) {
  return function (dispatch, getState) {
    return residentOnboardingApi
      .getResidentOnboarding(residentID)
      .then((residentOnboarding) => {
        dispatch(residentOnboardingUpdated(residentOnboarding));

        return residentOnboarding;
      });
  };
}

export function clearResidentOnboarding() {
  return function (dispatch, getState) {
    dispatch(residentOnboardingCleared());
  };
}

export function saveResidentOnboarding(header, residentID) {
  return function (dispatch, getState) {
    return residentOnboardingApi
      .saveResidentOnboarding(header, residentID)
      .then((residentOnboarding) => {
        dispatch(residentOnboardingHeaderUpdated(residentOnboarding));

        return residentOnboarding;
      });
  };
}

export function saveProviderService(providerService, residentID) {
  return function (dispatch, getState) {
    return residentOnboardingApi
      .saveProviderService(providerService, residentID)
      .then((result) => {
        if (result.updateResult) {
          dispatch(
            residentOnboardingProviderServiceUpdated({
              ...providerService,
              ...result.updateResult
            })
          );
        }

        if (result.checklist) {
          dispatch(residentOnboardingChecklistUpdated(result.checklist));
        }

        return result;
      });
  };
}

export function saveProviderServiceStatus(
  residentID,
  providerService,
  residentOnboardingStatusID,
  note
) {
  return function (dispatch, getState) {
    return residentOnboardingApi
      .saveProviderServiceStatus(
        residentID,
        providerService.ProviderServiceID,
        residentOnboardingStatusID,
        note
      )
      .then((result) => {
        if (result.updateResult) {
          if (result.checklist) {
            const updateResult = result.checklist.filter(
              (item) =>
                item.ProviderServiceID === providerService.ProviderServiceID
            )[0];
            if (updateResult) {
              result.updateResult = updateResult;
            }
          }

          dispatch(
            residentOnboardingProviderServiceUpdated({
              ...providerService,
              ...result.updateResult
            })
          );
        }

        if (result.checklist) {
          dispatch(residentOnboardingChecklistUpdated(result.checklist));
        }

        return result;
      });
  };
}

export function saveResidentVehicle(vehicle, residentID) {
  return function (dispatch, getState) {
    return residentOnboardingApi
      .saveResidentVehicle(vehicle, residentID)
      .then((result) => {
        if (result.updateResult) {
          dispatch(
            residentOnboardingVehicleUpdated({
              ...vehicle,
              ...result.updateResult
            })
          );
        }

        if (result.checklist) {
          dispatch(residentOnboardingChecklistUpdated(result.checklist));
        }

        return result;
      });
  };
}

export function saveResidentVehicleStatus(
  residentID,
  vehicle,
  residentOnboardingStatusID,
  note
) {
  return function (dispatch, getState) {
    return residentOnboardingApi
      .saveResidentVehicleStatus(
        residentID,
        vehicle.ResidentVehicleID,
        residentOnboardingStatusID,
        note
      )
      .then((result) => {
        if (result.checklist) {
          const updateResult = result.checklist.filter(
            (item) => item.ResidentVehicleID === vehicle.ResidentVehicleID
          )[0];
          if (updateResult) {
            result.updateResult = updateResult;
          }
        }

        if (result.updateResult) {
          dispatch(
            residentOnboardingVehicleUpdated({
              ...vehicle,
              ...result.updateResult
            })
          );
        }

        if (result.checklist) {
          dispatch(residentOnboardingChecklistUpdated(result.checklist));
        }

        return result;
      });
  };
}

export function addResidentVehicle(residentID) {
  return function (dispatch, getState) {
    return residentOnboardingApi
      .createResidentVehicle(residentID)
      .then((result) => {
        if (result.insertResult) {
          dispatch(
            residentOnboardingVehicleAdded({
              ...result.insertResult
            })
          );
        }

        if (result.checklist) {
          dispatch(residentOnboardingChecklistUpdated(result.checklist));
        }

        return result;
      });
  };
}

export function deleteResidentVehicle(vehicle, residentID) {
  return function (dispatch, getState) {
    return residentOnboardingApi
      .deleteResidentVehicle(vehicle.ResidentVehicleID, residentID)
      .then((result) => {
        dispatch(
          residentOnboardingVehicleDeleted({
            ...vehicle
          })
        );

        if (result.checklist) {
          dispatch(residentOnboardingChecklistUpdated(result.checklist));
        }

        return result;
      });
  };
}

export function saveResidentPet(pet, residentID) {
  return function (dispatch, getState) {
    return residentOnboardingApi
      .saveResidentPet(pet, residentID)
      .then((result) => {
        if (result.updateResult) {
          dispatch(
            residentOnboardingPetUpdated({
              ...pet.Pet,
              ...result.updateResult
            })
          );
        }

        if (result.checklist) {
          dispatch(residentOnboardingChecklistUpdated(result.checklist));
        }

        return result;
      });
  };
}

export function saveResidentPetStatus(
  residentID,
  pet,
  residentOnboardingStatusID,
  note
) {
  return function (dispatch, getState) {
    return residentOnboardingApi
      .saveResidentPetStatus(
        residentID,
        pet.ResidentPetID,
        residentOnboardingStatusID,
        note
      )
      .then((result) => {
        if (result.checklist) {
          const updateResult = result.checklist.filter(
            (item) => item.ResidentPetID === pet.ResidentPetID
          )[0];
          if (updateResult) {
            result.updateResult = updateResult;
          }
        }

        if (result.updateResult) {
          dispatch(
            residentOnboardingPetUpdated({
              ...pet,
              ...result.updateResult
            })
          );
        }

        if (result.checklist) {
          dispatch(residentOnboardingChecklistUpdated(result.checklist));
        }

        return result;
      });
  };
}

export function addResidentPet(residentID) {
  return function (dispatch, getState) {
    return residentOnboardingApi
      .createResidentPet(residentID)
      .then((result) => {
        if (result.insertResult) {
          dispatch(
            residentOnboardingPetAdded({
              ...result.insertResult
            })
          );
        }

        if (result.checklist) {
          dispatch(residentOnboardingChecklistUpdated(result.checklist));
        }

        return result;
      });
  };
}

export function deleteResidentPet(pet, residentID) {
  return function (dispatch, getState) {
    return residentOnboardingApi
      .deleteResidentPet(pet.ResidentPetID, residentID)
      .then((result) => {
        dispatch(
          residentOnboardingPetDeleted({
            ...pet
          })
        );

        if (result.checklist) {
          dispatch(residentOnboardingChecklistUpdated(result.checklist));
        }

        return result;
      });
  };
}

export function updateFileList(fileList) {
  return function (dispatch, getState) {
    return dispatch(residentFileListUpdated(fileList));
  };
}

export function deleteFile(residentFileID, residentID) {
  return function (dispatch, getState) {
    return residentOnboardingApi
      .deleteResidentFile(residentFileID, residentID)
      .then((result) => {
        dispatch(residentFileListUpdated(result));

        return result;
      });
  };
}

export function cropImage(
  residentFileID,
  residentID,
  percentWidth,
  percentHeight,
  percentX,
  percentY
) {
  return function (dispatch, getState) {
    return residentOnboardingApi
      .cropResidentFile(
        residentFileID,
        residentID,
        percentWidth,
        percentHeight,
        percentX,
        percentY
      )
      .then((result) => {
        if (result && result.length) {
          dispatch(residentFileListUpdated(result));
        }

        return result;
      });
  };
}

export function updateCroppedImage(
  residentFileID,
  residentID,
  croppedImageData
) {
  return function (dispatch, getState) {
    return residentOnboardingApi
      .updateCroppedImage(residentFileID, residentID, croppedImageData)
      .then((result) => {
        if (result && result.length) {
          dispatch(residentFileListUpdated(result));
        }

        return result;
      });
  };
}
