import React, { useState, useRef, useEffect } from 'react';
import { navigate } from 'gatsby-link';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './index.css';
import '../OfferGrid/index.css';

import Button from '@mui/material/Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LinearProgress from '@mui/material/LinearProgress';

import OfferRow from '../OfferGrid/OfferRow';
import OfferRowElectric from '../OfferGrid/OfferRowElectric';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';
import * as offerCalc from '../../api/offerCalc';

function CartMenu({
  actions,
  serviceAddress,
  setShowCartMenu,
  checkout,
  others,
  completed,
  siteConfig
}) {
  const [saving, setSaving] = useState(false);
  const savingTimeout = useRef();
  const [cartItemDictionary, setCartItemDictionary] = useState(null);

  useEffect(() => {
    let _cartItemDictionary = {
      inProgress: [],
      checkout: [],
      completed: []
    };

    if (serviceAddress && serviceAddress.Cart) {
      _cartItemDictionary.inProgress = serviceAddress.Cart.filter(
        (cartItem) =>
          !cartItem.IsCompleted &&
          (!checkout || checkout.ProductTypeID !== cartItem.ProductTypeID) &&
          cartItem.ProviderID > 0
      );

      _cartItemDictionary.checkout =
        checkout && checkout.ProviderID > 0 ? [checkout] : [];

      _cartItemDictionary.completed = serviceAddress.Cart.filter(
        (cartItem) =>
          cartItem.IsCompleted &&
          (!checkout || checkout.ProductTypeID !== cartItem.ProductTypeID)
      );
    }

    setCartItemDictionary(_cartItemDictionary);
  }, [serviceAddress, checkout]);

  function removeFromCart(cartItem) {
    savingTimeout.current = setTimeout(() => setSaving(true), 500);

    actions
      .saveOrder(
        serviceAddress.Address.Address,
        serviceAddress.Address.Secondary,
        serviceAddress.Address.City,
        serviceAddress.Address.State,
        serviceAddress.Address.Zip,
        serviceAddress.DeregulatedElectric
          ? serviceAddress.DeregulatedElectric.DeregulatedAddressID
          : null,
        cartItem.ProviderID,
        cartItem.OfferID,
        11,
        null,
        null,
        null,
        null,
        cartItem.ProductTypeID
      )
      .then((result) => {
        if (setShowCartMenu) {
          setShowCartMenu(false);
        }
        clearTimeout(savingTimeout.current);
        setSaving(false);
      });
  }

  function getBannerText(cartItem) {
    return cartItem.IsCompleted
      ? 'Completed ' +
          cartItem.ProviderServiceList.split(',').join(' /') +
          ' Order'
      : 'Selected ' +
          cartItem.ProviderServiceList.split(',').join(' /') +
          ' Offer';
  }

  return (
    <div className={'cart-menu' + (saving ? ' saving' : '')}>
      {saving ? <LinearProgress /> : null}
      {cartItemDictionary &&
      (cartItemDictionary.inProgress.length ||
        cartItemDictionary.checkout.length) ? (
        (completed
          ? cartItemDictionary.completed
          : checkout
          ? others
            ? cartItemDictionary.inProgress
            : cartItemDictionary.checkout
          : cartItemDictionary.inProgress
        ).map((cartItem) => {
          const offer = serviceAddress.OfferList.filter(
            (_offer) =>
              _offer.ProviderID === cartItem.ProviderID &&
              _offer.OfferID === cartItem.OfferID
          )[0];

          let providerServiceToggle = {};

          if (offer && offer.ProviderServiceList) {
            offer.ProviderServiceList.split(', ').map(
              (providerServiceName) =>
                (providerServiceToggle[providerServiceName] = true)
            );
          }

          return offer ? (
            <div className="cart-item">
              {cartItem.ProductTypeID === 6 ? (
                <div className="offer-grid electric">
                  <OfferRowElectric
                    offer={offer}
                    removeFromCart={removeFromCart}
                    bannerText={getBannerText(cartItem)}
                    condensed
                    buttonsComponent={
                      <OrderDetailsComponent
                        offer={offer}
                        removeFromCart={removeFromCart}
                        cartItem={cartItem}
                        checkout={checkout}
                        siteConfig={siteConfig}
                      />
                    }
                  />
                </div>
              ) : (
                <div className="offer-grid">
                  <OfferRow
                    offer={offer}
                    providerServiceToggle={providerServiceToggle}
                    removeFromCart={removeFromCart}
                    bannerText={getBannerText(cartItem)}
                    condensed
                    buttonsComponent={
                      <OrderDetailsComponent
                        offer={offer}
                        removeFromCart={removeFromCart}
                        cartItem={cartItem}
                        checkout={checkout}
                        siteConfig={siteConfig}
                      />
                    }
                  />
                </div>
              )}
            </div>
          ) : null;
        })
      ) : (
        <div className="cart-item empty">You have no items in your cart</div>
      )}
      {cartItemDictionary &&
      cartItemDictionary.inProgress &&
      cartItemDictionary.inProgress.length &&
      !checkout &&
      !completed ? (
        <Button
          variant="contained"
          color="secondary"
          style={{ float: 'right', marginBottom: '10px' }}
          onClick={() => {
            setShowCartMenu(null);
            navigate(
              offerCalc.getCheckoutRoute(cartItemDictionary.inProgress[0])
            );
          }}>
          Complete Order <ChevronRightIcon />
        </Button>
      ) : null}
    </div>
  );
}

function OrderDetailsComponent({
  offer,
  removeFromCart,
  cartItem,
  checkout,
  siteConfig
}) {
  function changeOffer() {
    navigate(offerCalc.getPlanSelectionRoute(offer, siteConfig.id));
  }

  return cartItem.IsCompleted ? (
    !checkout || checkout.ProductTypeID !== cartItem.ProductTypeID ? (
      <Button
        className="change-offer"
        href={
          offerCalc.getCheckoutRoute(cartItem) +
          '?order=' +
          cartItem.OrderNumber
        }>
        Order Summary
      </Button>
    ) : (
      <>&nbsp;</>
    )
  ) : (
    <>
      <Button className="change-offer" onClick={() => changeOffer()}>
        Change Offer
      </Button>
      {!checkout ? (
        <Button
          onClick={() => removeFromCart(cartItem)}
          className="change-offer">
          Remove from Cart
        </Button>
      ) : null}
    </>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      saveOrder: bindActionCreators(serviceAddressActions.saveOrder, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CartMenu);
