import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';

import StaticDatePicker from '@mui/lab/StaticDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';

import './index.css';

function DefaultStartDateEdit({
  serviceAddress,
  actions,
  authUser,
  siteConfig,
  route
}) {
  const [defaultStartDate, setDefaultStartDate] = useState(null);
  const [dateChanged, setDateChanged] = useState(null);

  useEffect(() => {
    if (serviceAddress && serviceAddress.Address && !defaultStartDate) {
      setDefaultStartDate(serviceAddress.Address.DefaultStartDate);
    }
  }, [serviceAddress]);

  useEffect(() => {
    if (dateChanged) {
      //actions.pageLoading(true, 'Saving your move-in date');

      actions
        .updateVisitorUserInfo({
          ...serviceAddress.Address,
          DefaultStartDate: defaultStartDate
        })
        .then((result) => {
          //actions.pageLoading(false);
          setDateChanged(false);
        });
    }
  }, [dateChanged]);

  return serviceAddress && serviceAddress.Address ? (
    <div className={'default-start-date-edit'}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StaticDatePicker
          orientation="landscape"
          openTo="day"
          value={defaultStartDate}
          views={['day']}
          disableHighlightToday={true}
          disablePast
          onChange={(date) => {
            setDefaultStartDate(date.toJSON());
            setDateChanged(true);
          }}
        />
      </LocalizationProvider>
    </div>
  ) : null;
}

const mapStateToProps = (state) => ({
  serviceAddress: state.serviceAddress,
  authUser: state.authUser,
  siteConfig: state.commonData.siteConfig
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      updateVisitorUserInfo: bindActionCreators(
        serviceAddressActions.updateVisitorUserInfo,
        dispatch
      ),
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultStartDateEdit);
