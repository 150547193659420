import axios from 'axios';

axios.defaults.baseURL = process.env.GATSBY_API_URL;

let defaultConfig = null;
let authUserConfig = null;

export function setDefaultConfig(connectionId, visitorKey, visitKey) {
  if (visitorKey && visitKey) {
    defaultConfig = {
      headers: {
        connectionId: connectionId,
        visitorKey: visitorKey,
        visitKey: visitKey,
        key: 'vI7uE2aK6bL6jS2nN5lV2oT2kI0hJ6nRvI7uE2aK6bL6jS2nN5lV2oT2kI0hJ6nR',
        brandId: process.env.GATSBY_BRAND_ID
      }
    };
  } else {
    defaultConfig = {};
  }
}

export function setAuthUserConfig(email, uid, token) {
  if (email && uid && token) {
    authUserConfig = {
      email: email,
      uid: uid,
      token: token
    };
  } else {
    authUserConfig = {};
  }
}

export function getConfigObject(appendToDefaultConfig) {
  let _config = { ...defaultConfig };

  if (authUserConfig) {
    if (!_config.headers) {
      _config.headers = {};
    }
    _config.headers = {
      ..._config.headers,
      ...authUserConfig
    };
  }

  if (appendToDefaultConfig) {
    if (appendToDefaultConfig.headers) {
      _config.headers = {
        ..._config.headers,
        ...appendToDefaultConfig.headers
      };
    }
  }

  return _config;
}

export function get(url, config) {
  return axios
    .get(url, getConfigObject(config))
    .then(function (response) {
      return response;
    })
    .catch(handleError);
}

export function post(url, data, config) {
  return axios
    .post(url, data, getConfigObject(config))
    .then(function (response) {
      return response;
    })
    .catch(handleError);
}

export function handleError(error) {
  // eslint-disable-next-line no-console

  throw error;
}

//FROM PLURALSIGHT:

export function handleResponse(response) {
  if (response.ok) return response.json();
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = response.text();
    throw new Error(error);
  }
  //throw new Error('Network response was not ok.');
}
