import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { navigate } from 'gatsby-link';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';
import * as offerCalc from '../../api/offerCalc';

import DemoModePopup from '../Dashboard/DemoModePopup';

function OrderButton({
  offer,
  secondary,
  serviceAddress,
  actions,
  webSocket,
  label
}) {
  const loadingTimeout = useRef();
  const [showDemoModePopup, setShowDemoModePopup] = useState(null);

  useEffect(() => {
    // cleanup function to cancel the timeout if it hasn't finished.
    return () => {
      if (loadingTimeout.current) {
        clearTimeout(loadingTimeout.current);
      }
    };
  }, []);

  function trackOrder(event, isCallIn) {
    if (webSocket.mockRoute) {
      setShowDemoModePopup(true);
      return false;
    }
    loadingTimeout.current = setTimeout(() => {
      actions.pageLoading(true, 'Saving your selection...');
    }, 500);

    actions
      .saveOrder(
        serviceAddress.Address.Address,
        serviceAddress.Address.Secondary,
        serviceAddress.Address.City,
        serviceAddress.Address.State,
        serviceAddress.Address.Zip,
        serviceAddress.DeregulatedElectric
          ? serviceAddress.DeregulatedElectric.DeregulatedAddressID
          : null,
        offer.ProviderID,
        offer.OfferID,
        isCallIn ? 4 : 1,
        null, //offsiteOrderUrl,
        null, //providerAccountNumber,
        null, //additionalDetails,
        null, //checkoutStep,
        null, //productTypeID,
        null, //offer,
        true //createOrderOnly
      )
      .then((result) => {
        if (loadingTimeout.current) {
          clearTimeout(loadingTimeout.current);
        }

        actions.pageLoading(false);

        if (!isCallIn) {
          navigate(offerCalc.getCheckoutRoute(offer));
        }
      });

    event.stopPropagation();
  }

  return (
    <div className="buttons">
      <div>
        <Button
          variant={label ? 'contained' : 'outlined'}
          disabled={!webSocket.initted || serviceAddress.mock}
          color="secondary"
          className="order-online"
          onClick={(event) => {
            if (offer.OfferStatusID !== 5) {
              trackOrder(event);
            }
          }}
          href={offer.OfferStatusID !== 5 ? null : offer.OffsiteSignUpUrl}
          target={offer.OfferStatusID !== 5 ? '_self' : 'mi_offsite'}>
          {label
            ? label
            : !serviceAddress ||
              !serviceAddress.Address ||
              !serviceAddress.Address.Address
            ? 'Select this Plan'
            : 'Select this Plan'}{' '}
          <ChevronRightIcon />
        </Button>
        {offer.OrderPhone && !label ? (
          <Button
            variant="text"
            className="order-phone"
            href={'tel:' + offer.OrderPhone}
            onClick={(event) => trackOrder(event, true)}>
            {secondary ? (
              <div>To order call:&nbsp;</div>
            ) : (
              <span>or call: &nbsp;</span>
            )}
            <strong>{offer.OrderPhone}</strong>
          </Button>
        ) : null}
      </div>

      {showDemoModePopup ? (
        <DemoModePopup closeFunc={() => setShowDemoModePopup(null)} />
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      saveOrder: bindActionCreators(serviceAddressActions.saveOrder, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderButton);
