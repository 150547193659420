import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { navigate } from 'gatsby-link';
import { withPrefix } from 'gatsby-link';

import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Alert
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CloseIcon from '@mui/icons-material/Close';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';
import * as userAuthActions from '../../redux/actions/userAuthActions';
import * as residentOnboardingActions from '../../redux/actions/residentOnboardingActions';

import UserPropertySelect, {
  selectSampleResident,
  setSelectedResident
} from '../UserPropertySelect';

import PropertyMenu from './index';
import AddressSearch from '../AddressSearch';

import './index.css';

function PropertyMenuPopup({
  siteConfig,
  authUser,
  actions,
  formOnly,
  closeFunc
}) {
  const [showAddressSearch, setShowAddressSearch] = useState(false);
  const [addressSearched, setAddressSearched] = useState(false);

  return authUser &&
    authUser.User &&
    authUser.User.Token &&
    authUser.PropertyList &&
    authUser.PropertyList.length ? (
    <>
      <Dialog
        open={true}
        aria-labelledby="form-dialog-title"
        className={'property-menu-popup' + (formOnly ? ' form-only' : '')}
        fullWidth={true}>
        <DialogTitle id="form-dialog-title">
          <img
            src={withPrefix('/images/logo-icon.svg')}
            className="logo-icon"
          />
          Your {siteConfig.brand} Properties
          {!formOnly ? (
            <div className="property-menu-dialog-close-style">
              <IconButton
                style={{ float: 'right' }}
                aria-label="close"
                onClick={() => closeFunc()}>
                <CloseIcon />
              </IconButton>
            </div>
          ) : null}
        </DialogTitle>
        <DialogContent width="100%">
          {authUser && authUser.User && authUser.User.IsAdmin ? (
            <div className="admin-menu" style={{ margin: '10px' }}>
              <span className="admin-title">Admin Only</span>
              <div style={{ marginTop: '-10px', marginBottom: '-20px' }}>
                <AddressSearch
                  modal={true}
                  onSelected={() => {
                    setShowAddressSearch(false);
                    setAddressSearched(false);
                  }}
                  autoFocus={false}
                  hideHistory
                  hideSearchBy
                  onAddressSearched={() => setAddressSearched(true)}
                />
              </div>
            </div>
          ) : null}

          <p className="get-started-text">
            To get started, please select one of the following properties:
          </p>
          <div className="property-menu-dialog-container">
            <PropertyMenu />
          </div>
        </DialogContent>
      </Dialog>
    </>
  ) : null;
}

const mapStateToProps = (state) => ({
  serviceAddress: state.serviceAddress,
  authUser: state.authUser,
  siteConfig: state.commonData.siteConfig
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      serviceAddressUpdated: bindActionCreators(
        serviceAddressActions.serviceAddressUpdated,
        dispatch
      ),
      selectUnit: bindActionCreators(userAuthActions.selectUnit, dispatch),
      clearResidentOnboarding: bindActionCreators(
        residentOnboardingActions.clearResidentOnboarding,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyMenuPopup);
