import React from 'react';

export const providerLogoBaseUrl =
  'https://sc-cdn-public.s3.us-west-2.amazonaws.com/';

function ProviderLogo({ offer }) {
  const logoUrl = offer.LogoFile
    ? offer.LogoFile.indexOf('http') === -1
      ? providerLogoBaseUrl + offer.LogoFile
      : offer.LogoFile
    : '';

  return (
    <div className="provider-logo-container">
      {logoUrl ? (
        <img src={logoUrl} className="provider-logo" alt={offer.ProviderName} />
      ) : (
        <div className="provider-name-text">{offer.ProviderName}</div>
      )}
      {offer.PUCNumber ? (
        <div className="puct">REP #{offer.PUCNumber}</div>
      ) : null}
    </div>
  );
}

export default ProviderLogo;
