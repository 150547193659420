import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from '@mui/material/Button';
import WifiIcon from '@mui/icons-material/Wifi';

function ConnectSmartMeterButton({
  serviceAddress,
  setShowUsageHistoryRequest
}) {
  return serviceAddress.DeregulatedElectric &&
    (serviceAddress.DeregulatedElectric.IsSmartMeter ||
      !serviceAddress.Address.Address)
    ? null //For now until Bud has us put the connect smart meter back
    : // <Button
      //   variant="contained"
      //   color="secondary"
      //   className="connect-smart-meter"
      //   onClick={() => setShowUsageHistoryRequest({ inline: true })}>
      //   <WifiIcon /> &nbsp;Connect Smart Meter
      //   {/* <ChevronRightIcon className="arrow" /> */}
      // </Button>
      null;
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectSmartMeterButton);
