import React, { useEffect, useRef, useState } from 'react';
import ReactCodeInput from 'react-code-input';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import {
  validateEmail,
  requestPasswordReset,
  PasswordPolicy,
  validatePassword,
  confirmPasswordReset
} from '../../api/userAuthApi';

import './index.css';
function UserForgotPassword({ closeFunc, email, passwordUpdatedFunc }) {
  const [request, setRequest] = useState({
    Email: email,
    PasswordResetCode: ''
  });

  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className="user-forgot-password-popup"
      fullWidth={true}>
      <DialogTitle id="form-dialog-title">
        <IconButton
          style={{ float: 'right' }}
          aria-label="close"
          onClick={() => {
            closeFunc();
          }}>
          <CloseIcon />
        </IconButton>
        <img src={withPrefix('/images/logo-icon.svg')} className="logo-icon" />
        Password Help
      </DialogTitle>
      <DialogContent width="100%" style={{ paddingTop: '20px' }}>
        {request.Sent ? (
          <>
            <p>A password reset code has been sent to {request.Email}.</p>
            <p>
              <strong>
                Please check your email. Enter the code from the email in the
                field below. Then enter a new password.
              </strong>
            </p>

            <div style={{ textAlign: 'center' }}>
              {request.Error ? (
                <Alert
                  severity="error"
                  style={{ margin: '10px', textAlign: 'left' }}>
                  {request.Error}
                </Alert>
              ) : null}
              <ReactCodeInput
                fullWidth
                type="number"
                fields={6}
                value={request.PasswordResetCode}
                onChange={(value) =>
                  setRequest({
                    ...request,
                    PasswordResetCode: value
                  })
                }
              />

              <div>
                <TextField
                  value={request.NewPassword}
                  label={'Create New Password'}
                  type={request.ShowPassword ? 'text' : 'password'}
                  required
                  onChange={(event) =>
                    setRequest({
                      ...request,
                      NewPassword: event.target.value
                    })
                  }
                  helperText={PasswordPolicy}
                  style={{ width: '255px', marginTop: '20px' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setRequest({
                              ...request,
                              ShowPassword: !request.ShowPassword
                            })
                          }>
                          {request.ShowPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  fullWidth
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <p>
              If you've forgotten your password, you may request a password
              reset code using the form below.
            </p>

            <TextField
              label={'Your Email'}
              fullWidth
              required
              type="email"
              value={request.Email}
              onChange={(event) =>
                setRequest({ ...request, Email: event.target.value })
              }></TextField>
          </>
        )}
      </DialogContent>
      <DialogActions style={{ display: 'block', textAlign: 'right' }}>
        {request.Sent ? (
          <Button
            variant="contained"
            color="secondary"
            disabled={
              !request.PasswordResetCode ||
              !request.NewPassword ||
              !validatePassword(request.NewPassword)
            }
            onClick={() =>
              confirmPasswordReset(request).then((result) => {
                if (result.RowsUpdated) {
                  passwordUpdatedFunc({ ...request });
                } else {
                  setRequest({
                    ...request,
                    Error:
                      'The code you entered is not valid. Please try again.'
                  });
                }
              })
            }>
            Submit
          </Button>
        ) : (
          <Button
            variant="contained"
            color="secondary"
            disabled={!request.Email || !validateEmail(request.Email)}
            onClick={() => {
              requestPasswordReset(request.Email).then((result) =>
                setRequest({ ...request, Sent: true })
              );
            }}>
            Email Code
          </Button>
        )}
        <Button onClick={() => closeFunc()}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {}
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserForgotPassword);
