import React, { useState, useEffect, lazy } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Alert
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import InputMask from 'react-input-mask';

import * as dataApi from '../../api/dataApi';
import { validateEmail } from '../../api/userAuthApi';

function InvitationRequest({ siteConfig, inline, disableAutoFocus }) {
  const [invitationRequest, setInvitationRequest] = useState({});

  return invitationRequest.Requested ? (
    <p>Thanks! Please check your email for your welcome message.</p>
  ) : (
    <>
      {!inline ? (
        <>
          {/* <p>
            To access your {siteConfig.brand} dashboard, please provide your
            name and email address below and we'll send you an invitation link.
          </p> */}
          <p>
            Residents moving in to a property that partners with{' '}
            {siteConfig.brand} receive a welcome message via email prior to
            their move-in date, which has directions for accessing the{' '}
            {siteConfig.brand} Dashboard.
          </p>
          <p>
            <strong>
              To request another copy of your welcome message, enter your email
              address in the form below.
            </strong>
          </p>
        </>
      ) : null}

      {/* <TextField
        label="First Name"
        variant="outlined"
        required={true}
        fullWidth={true}
        autoFocus={true}
        value={invitationRequest.FirstName}
        onChange={(e) =>
          setInvitationRequest({
            ...invitationRequest,
            FirstName: e.target.value
          })
        }
        style={{ marginBottom: '15px' }}
      />

      <TextField
        label="Last Name"
        variant="outlined"
        required={true}
        fullWidth={true}
        value={invitationRequest.LastName}
        onChange={(e) =>
          setInvitationRequest({
            ...invitationRequest,
            LastName: e.target.value
          })
        }
        style={{ marginBottom: '15px' }}
      /> */}

      <TextField
        label="Email"
        type="email"
        variant="outlined"
        required={true}
        fullWidth={true}
        style={{ marginBottom: '15px' }}
        value={invitationRequest.Email}
        onChange={(e) =>
          setInvitationRequest({
            ...invitationRequest,
            Email: e.target.value
          })
        }
      />

      {/* <InputMask
        mask={'999-999-9999'}
        value={invitationRequest.Phone}
        onChange={(e) =>
          setInvitationRequest({
            ...invitationRequest,
            Phone: e.target.value
          })
        }>
        {() => (
          <TextField
            placeholder={'555-555-5555'}
            variant="outlined"
            fullWidth={true}
            label="Cell Phone"
          />
        )}
      </InputMask> */}

      <Button
        onClick={() => {
          setInvitationRequest({
            ...invitationRequest,
            Requesting: true
          });

          dataApi.requestInvitation({ ...invitationRequest }).then((result) => {
            setInvitationRequest({
              ...invitationRequest,
              Requested: true
            });
          });
        }}
        variant="contained"
        style={{ marginTop: '-60px', marginRight: '10px', float: 'right' }}
        disabled={
          !invitationRequest.Email || !validateEmail(invitationRequest.Email)
        }
        color="primary">
        {invitationRequest.Requesting ? 'Submitting...' : 'Submit'}
      </Button>
    </>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    authUser: state.authUser,
    loading: state.apiCallsInProgress > 0,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {}
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InvitationRequest);
