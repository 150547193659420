import axios from 'axios';
import * as api from './apiUtils';

export function searchAddress(search, selected) {
  return axios
    .get(
      'https://us-autocomplete-pro.api.smartystreets.com/lookup?key=59026541507367534&search=' +
        search +
        '&selected=' +
        selected +
        '&source=all'
    )
    .then(function (response) {
      return response.data;
    });
}

export function searchERCOTAddress(zip, searchString) {
  return api
    .post(
      'API/Data/SearchERCOTAddress?zip=' +
        zip +
        '&searchString=' +
        encodeURIComponent(searchString)
    )
    .then((response) => {
      return response.data;
    });
}
