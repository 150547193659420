import React from 'react';
import Button from '@mui/material/Button';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LinearProgress from '@mui/material/LinearProgress';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import ProviderLogo from './ProviderLogo';
import OrderButton from './OrderButton';
import InternetChart from './InternetChart';

import { withPrefix } from 'gatsby-link';

import * as offerCalc from '../../api/offerCalc';

function OfferRow({
  offer,
  offerDictionary,
  setOfferDictionary,
  columnVisibility,
  removeFromCart,
  bannerText,
  buttonsComponent,
  condensed,
  showDetails
}) {
  if (!columnVisibility) {
    columnVisibility = {
      TermLength: offer.TermLength,
      InternetDownloadSpeedMb: offer.InternetDownloadSpeedMb,
      TVChannelCount: offer.TVChannelCount,
      PriceDollars: offer.PriceDollars
    };
  }

  function getBreakdown() {
    let chargeBreakdown = offer.detailList;
    let grandTotal = 0;

    let currentGroup = null;
    let breakdown = [];
    chargeBreakdown.map((item) => {
      if (!currentGroup || item.category !== currentGroup.category) {
        if (currentGroup) {
          breakdown.push(currentGroup);
        }

        currentGroup = {
          category: item.category,
          subtotal: item.amount,
          itemList: [item]
        };
      } else {
        currentGroup.subtotal += item.amount;
        currentGroup.itemList.push(item);
      }

      grandTotal += item.amount;
    });

    if (currentGroup) {
      breakdown.push(currentGroup);
    }

    breakdown.push({
      grandTotal: true,
      amount: grandTotal
    });

    return breakdown;
  }

  return (
    <div
      className={
        'offer-row' +
        ((offerDictionary &&
          offerDictionary[offer.OfferID] &&
          offerDictionary[offer.OfferID].expanded) ||
        showDetails
          ? ' expanded'
          : '') +
        (offer.hasBadges ? ' has-badges' : '') +
        (offer.HasSecondaryProperties ? ' has-secondary' : '') +
        (removeFromCart !== undefined ? ' no-buttons' : '') +
        (condensed !== undefined ? ' condensed' : '')
      }
      id={'offer' + offer.OfferID + (bannerText ? '-a' : '')}
      onClick={() => {
        if (offer.OfferStatusID !== 5) {
          if (setOfferDictionary) {
            setOfferDictionary({
              ...offerDictionary,
              [offer.OfferID]: offerDictionary[offer.OfferID]
                ? {
                    ...offerDictionary[offer.OfferID],
                    expanded: !offerDictionary[offer.OfferID].expanded
                  }
                : { expanded: true }
            });
          }
        }
      }}>
      {bannerText ? <h2>{bannerText}</h2> : null}
      <div className="brand">
        <ProviderLogo offer={offer} />
      </div>
      <div className="primary-data">
        {columnVisibility['TermLength'] ? (
          <div className="data-point">
            <div className="content">
              {offer.TermLength > 1 ? (
                <>
                  <div className="title">
                    {offer.TermTypeID === 3 ? 'No Contract' : 'Contract Term'}
                  </div>
                  <div className="data">
                    <CalendarTodayIcon />
                    <div className="term-length-label">{offer.TermLength}</div>
                  </div>
                  <div className="unit">
                    {offer.TermTypeID === 3
                      ? 'Price Lock'
                      : offer.TermLength + ' months'}
                  </div>
                </>
              ) : offer.TermLength !== null ? (
                <>
                  <div className="title">No Contract</div>
                  <div className="data">
                    <LockOpenIcon />
                  </div>
                  <div className="unit">Monthly</div>
                </>
              ) : (
                <>
                  <div className="title">Check with</div>
                  <div className="data">
                    <CalendarTodayIcon />
                  </div>
                  <div className="unit">Provider</div>
                </>
              )}
            </div>
          </div>
        ) : null}
        {columnVisibility['InternetDownloadSpeedMb'] ? (
          <div className="data-point">
            <div className="content">
              {offer.InternetDownloadSpeedMb ? (
                <>
                  <div className="title">Download Speed</div>
                  <div className="data">
                    {offer.InternetDownloadSpeedMb}
                  </div>{' '}
                  <div className="unit">Mbps</div>
                </>
              ) : null}
            </div>
          </div>
        ) : null}
        {columnVisibility['TVChannelCount'] ? (
          <div className="data-point">
            <div className="content">
              {offer.TVChannelCount ? (
                <>
                  <div className="title">TV</div>
                  <div className="data">{offer.TVChannelCount}</div>
                  <div className="unit">channels</div>
                </>
              ) : null}
            </div>
          </div>
        ) : null}
        {columnVisibility['PriceDollars'] ? (
          <div className="data-point price">
            {offer.PriceDollars ? (
              <div className="content">
                <div className="title">
                  {offer.Term_Rate1_Start && offer.Term_Rate1_End
                    ? 'Months ' +
                      offer.Term_Rate1_Start +
                      '-' +
                      offer.Term_Rate1_End
                    : 'Advertised Price'}
                </div>
                <div className="data">
                  <span className="sup">$</span>
                  {offer.PriceDollars.toFixed(2).split('.')[0]}
                  <span className="sup">
                    {offer.PriceDollars.toFixed(2).split('.')[1]}
                  </span>
                </div>
                <div className="unit">
                  per month{offer.TermLength === 24 ? '*' : ''}
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>

      {removeFromCart === undefined ? (
        <div className="action">
          <OrderButton offer={offer} />
        </div>
      ) : null}

      <div className="middle">
        <ProviderLogo offer={offer} />

        <div className="secondary-order-buttons">
          {removeFromCart === undefined && !buttonsComponent ? (
            <OrderButton offer={offer} secondary />
          ) : buttonsComponent ? (
            buttonsComponent
          ) : null}
        </div>
      </div>

      <div className="footer">
        {offer.DisplayName ? (
          <div className="offer-name">{offer.DisplayName}</div>
        ) : null}
        {offer.termRateDisclaimer ? (
          <div className="offer-headline">
            <span className="hide-mobile">&nbsp;-&nbsp;</span>*
            {offer.termRateDisclaimer}
          </div>
        ) : !offer.OfferID ? (
          <div
            className="offer-headline"
            style={{ paddingTop: '5px', paddingLeft: '10px' }}>
            This provider may have service in your area but we do not have any
            information on their plan offers or pricing.
          </div>
        ) : null}

        <div className="badges">
          {offer.PhoneText ? (
            <div className="badge">{offer.PhoneText}</div>
          ) : null}
          {offer.HomeSecurityText ? (
            <div className="badge">{offer.HomeSecurityText}</div>
          ) : null}

          {offer.FeatureBadge1 ? (
            <div className="badge feature">{offer.FeatureBadge1}</div>
          ) : null}

          {offer.badgeImageList
            ? offer.badgeImageList.map((badgeImage) => (
                <div className="badge image">
                  <img src={withPrefix('/images/badge/' + badgeImage)} />
                </div>
              ))
            : null}
        </div>

        <div className="details-toggle">
          {!buttonsComponent && !showDetails ? (
            offer.OfferStatusID !== 5 ? (
              <Button>
                Plan Details <ExpandMoreIcon />
              </Button>
            ) : null
          ) : (
            buttonsComponent
          )}
        </div>

        <div className="spacer"></div>
      </div>
      <div className="offer-details">
        {(offerDictionary && offerDictionary[offer.OfferID]) || showDetails ? (
          <div className="container">
            <div className="top-details">
              {offer.SummaryText ? (
                <div
                  className="summary-text"
                  dangerouslySetInnerHTML={{ __html: offer.SummaryText }}></div>
              ) : null}
              {/* {offer.TermLength > 1 ? (
                <div className="detail-group">
                  <strong>Early Termination Fee</strong>$
                  {offer.TerminationFeeAmount
                    ? offer.TerminationFeeAmount.toFixed(0)
                    : '0'}{' '}
                  {offer.TerminationFeeTypeID == 2
                    ? ' per month remaining in contract'
                    : ''}
                </div>
              ) : null}

              {offer.InstallationFee ? (
                <div className="detail-group">
                  <strong>Installation Fee</strong>$
                  {offerCalc.round(offer.InstallationFee, 2).toFixed(0)}
                </div>
              ) : null}

              {offer.InternetRequired ? (
                <div className="detail-group">
                  <strong>Data Allowance</strong>
                  {offer.DataMaxGb ? (
                    <>{offerCalc.round(offer.DataMaxGb, 0).toFixed(0)} GB</>
                  ) : (
                    <span className="on">
                      <CheckCircleIcon />
                      No data caps
                    </span>
                  )}
                </div>
              ) : null}

              {offer.AutoPayDiscount ? (
                <div className="detail-group">
                  <strong>AutoPay Discount</strong>$
                  {Math.abs(offerCalc.round(offer.AutoPayDiscount, 2)).toFixed(
                    2
                  )}
                  {offer.AutoPayDiscountTerm
                    ? ' first ' + offer.AutoPayDiscountTerm + ' months'
                    : null}
                  {offer.EBillOnly ? ' (requires e-bill)' : null}
                </div>
              ) : offer.EBillOnly ? (
                <div className="detail-group">
                  <strong>e-Billing</strong>Requires e-bill
                </div>
              ) : null} */}
            </div>

            <InternetChart offer={offer} desktop />

            <div className="offer-section breakdown">
              <h5>
                <span>
                  <span>Breakdown of Charges</span>
                </span>
              </h5>

              <TableContainer>
                <Table aria-label="simple table">
                  {getBreakdown().map((itemGroup) =>
                    itemGroup.grandTotal ? (
                      <TableHead>
                        <TableRow className="footer">
                          <TableCell>One-month Estimate</TableCell>
                          <TableCell>
                            {offerCalc.formatDollars(itemGroup.amount)}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    ) : (
                      <>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan="2">
                              {itemGroup.category}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {itemGroup.itemList.map((item) => (
                            <TableRow
                              className={
                                item.isBillCredit
                                  ? item.amount
                                    ? 'credit'
                                    : 'no-credit'
                                  : ''
                              }>
                              <TableCell
                                colSpan={
                                  item.description !== 'Usage Discount' ? 1 : 2
                                }>
                                {item.description ? (
                                  <>
                                    <strong>
                                      {item.description}
                                      {item.rate ? ':' : ''}
                                    </strong>{' '}
                                  </>
                                ) : null}
                                <span
                                  className={
                                    item.rate && item.rate.length < 50
                                      ? 'no-break'
                                      : ''
                                  }>
                                  {item.rate}
                                </span>
                              </TableCell>
                              {item.description !== 'Usage Discount' ? (
                                <TableCell>
                                  {item.description !== 'Usage Discount'
                                    ? offerCalc.formatDollars(item.amount)
                                    : ''}
                                </TableCell>
                              ) : null}
                            </TableRow>
                          ))}
                          <TableRow className="footer">
                            <TableCell>
                              Sub-Total {itemGroup.category}:
                            </TableCell>
                            <TableCell>
                              {offerCalc.formatDollars(itemGroup.subtotal)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </>
                    )
                  )}
                </Table>
              </TableContainer>
            </div>

            <div class="offer-section notice">
              These estimated costs include all recurring charges you'll see on
              your bill, excluding government fees and taxes. The calculations
              are based on information provided by {offer.ProviderName}. Your
              actual costs will vary depending on actual usage.
            </div>
          </div>
        ) : (
          <LinearProgress />
        )}
      </div>
    </div>
  );
}

export default OfferRow;
