import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { navigate } from 'gatsby-link';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Popover
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';

import './index.css';

import CartMenu from '../CartMenu';

import * as dataApi from '../../api/dataApi';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';

function ServiceAddressBox({
  serviceAddress,
  actions,
  authUser,
  siteConfig,
  route,
  showMyProfile,
  setShowUserProfile,
  webSocket
}) {
  const [showCartMenu, setShowCartMenu] = useState(null);
  const [cartExists, setCartExists] = useState(false);
  const [cartItemCount, setCartItemCount] = useState(0);
  const [editDefaultStartDate, setEditDefaultStartDate] = useState(null);

  useEffect(() => {
    setCartExists(
      serviceAddress &&
        serviceAddress.Cart &&
        serviceAddress.Cart.filter(
          (_cartItem) => !_cartItem.IsCompleted && _cartItem.ProviderID > 0
        ).length > 0
    );

    setCartItemCount(
      serviceAddress && serviceAddress.Cart
        ? serviceAddress.Cart.filter(
            (_cartItem) => !_cartItem.IsCompleted && _cartItem.ProviderID > 0
          ).length
        : 0
    );
  }, [serviceAddress]);

  return serviceAddress && serviceAddress.Address ? (
    <>
      <div
        className={
          'service-address-box' +
          (cartExists && route.indexOf('checkout') === -1 ? ' with-cart' : '')
        }>
        {/* {authUser ? (
          <Button
            className="close"
            onClick={() => {
              actions.clearServiceAddress();
              actions.pageLoading(true, 'Reloading the page...');
              setTimeout(() => {
                if (window) {
                  window.location.reload();
                }
              }, 500);
            }}>
            <CloseIcon />
          </Button>
        ) : null} */}

        <div className="header-item service-address-block">
          {showMyProfile &&
          (!authUser || !authUser.User || !authUser.User.Token) &&
          (!webSocket ||
            !webSocket.invitation ||
            !webSocket.invitation.Role ||
            webSocket.invitation.Role === 'Resident') ? (
            <Button
              color="inherit"
              className="user-profile"
              onClick={() => {
                setShowUserProfile(true);
              }}>
              <PersonIcon />
              <span className="hide-mobile">My Profile</span>
            </Button>
          ) : null}
        </div>

        {cartExists &&
        route.indexOf('checkout') === -1 &&
        route.indexOf('plan-builder') === -1 &&
        siteConfig.id === 'sc' ? (
          <div className="header-item cart">
            <Button
              onClick={(event) => {
                setShowCartMenu(event.currentTarget);
              }}>
              <ShoppingCartIcon />
              <span className="count">
                {cartItemCount}
                <span className="hide-mobile"> item</span>
                {cartItemCount === 1 ? '' : 's'}
                <ExpandMoreIcon />
              </span>
            </Button>
            <Popover
              open={Boolean(showCartMenu)}
              anchorEl={showCartMenu}
              onClose={() => setShowCartMenu(null)}
              fullWidth={true}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              className="cart-menu-popover"
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              PaperProps={{
                style: { width: '100%', maxWidth: '700px' }
              }}>
              <CartMenu
                serviceAddress={serviceAddress}
                setShowCartMenu={setShowCartMenu}
                siteConfig={siteConfig}
              />
            </Popover>
          </div>
        ) : null}
      </div>
    </>
  ) : null;
}

const mapStateToProps = (state) => ({
  webSocket: state.webSocket,
  serviceAddress: state.serviceAddress,
  authUser: state.authUser,
  siteConfig: state.commonData.siteConfig
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      clearServiceAddress: bindActionCreators(
        serviceAddressActions.clearServiceAddress,
        dispatch
      ),
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceAddressBox);
