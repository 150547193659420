import React from 'react';

import Button from '@mui/material/Button';

export default function ({ error }) {
  console.warn('App Error', error);
  return (
    <div className="app-error">
      Oops! An error occurred. Please refresh the page to continue.
      <br />
      <br />
      <Button variant="contained" onClick={() => window.location.reload()}>
        Refresh
      </Button>
    </div>
  );
}
