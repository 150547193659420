import React from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { withPrefix } from 'gatsby-link';

function IFrameDialog({ title, url, closeFunc }) {
  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className="checkist-prompt-popup"
      fullScreen>
      <DialogTitle id="form-dialog-title">
        <IconButton
          style={{ float: 'right' }}
          aria-label="close"
          onClick={() => {
            closeFunc();
          }}>
          <CloseIcon />
        </IconButton>
        <img src={withPrefix('/images/logo-icon.svg')} className="logo-icon" />
        {title}
      </DialogTitle>
      <DialogContent width="100%">
        <iframe
          frameBorder="0"
          scrolling="auto"
          width="100%"
          height="100%"
          src={url}></iframe>
      </DialogContent>
    </Dialog>
  );
}

export default IFrameDialog;
