import React, { useEffect, useRef, useState } from 'react';
import Loadable from '@loadable/component';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Skeleton from '@mui/material/Skeleton';

import ConnectSmartMeterButton from './ConnectSmartMeterButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import * as dataApi from '../../api/dataApi';
import * as offerCalc from '../../api/offerCalc';

const summaryRow = dataApi.getMonthsOfYear();

const _chartOptions = {
  chart: {
    id: 'basic-bar',
    toolbar: {
      show: false
    },
    zoom: {
      enabled: false
    }
  },
  // plotOptions: {
  //   bar: {
  //     //columnWidth: '50%',
  //     strokeWidth: 1,
  //     endingShape: 'arrow'
  //   }
  // },
  stroke: {
    width: [2, 0]
    //dashArray: [5, 0]
  },

  colors: [
    '#26e7a6'
    // function (params) {
    //   if (params && params.w && params.w.config && params.w.config.series) {
    //     const series = params.w.config.series[params.seriesIndex];
    //     if (series && series.data) {
    //       const data = series.data[params.dataPointIndex];
    //       if (data) {
    //         switch (data.type) {
    //           case 'average':
    //             return '#ff3366'; //Average
    //           case 'projected':
    //             return '#8effd8'; //Projected
    //           default:
    //             return '#26e7a6'; //Actual / Estimated
    //         }
    //       }
    //     }
    //   }
    //   return '#999999';
    // }
  ],

  xaxis: {
    categories: []
  },
  markers: {
    size: 0,
    strokeWidth: 3,
    fillOpacity: 0,
    strokeOpacity: 0,
    hover: {
      size: 8
    }
  },
  yaxis: {
    tickAmount: 5,
    min: 0,
    max: 0,
    showForNullSeries: false
  }
};

function dollarsFormatter(value) {
  return '$' + offerCalc.round(value, 2);
}

function InternetChart({ desktop, offer, noDefaultTab }) {
  const [dataExpanded, setDataExpanded] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [dataSource, setDataSource] = useState(null);
  const [chartOptions, setChartOptions] = useState(null);
  const [chartData, setChartData] = useState(null);
  const chartTimeout = useRef();
  const chartApi = useRef();
  const chartOptionsTimeout = useRef();
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    if (chartTimeout.current) {
      clearTimeout(chartTimeout.current);
    }

    setChartOptions(null);
    chartTimeout.current = setTimeout(updateChart, 0);

    if (!activeTab) {
      if (noDefaultTab) {
        setActiveTab('None');
      } else {
        let _activeTab;
        if (offer.FeaturesLabel) {
          _activeTab = 'features';
        } else if (offer.DisclosuresLabel) {
          _activeTab = 'disclosures';
        } else if (offer.ChannelsLabel) {
          _activeTab = 'channels';
        }

        if (_activeTab) {
          setActiveTab(_activeTab);
        }
      }
    }
  }, [offer]);

  function updateChart() {
    let _tableData = offer.priceTable;

    let _chartData = {
      name: 'Monthly Total',
      type: 'line',
      data: []
    };

    let _max = 0;

    if (_tableData) {
      _tableData.map((price) => {
        _chartData.data.push({
          x: 'Month ' + price.monthNumber,
          y: price.amount
        });

        if (!_max || price.amount > _max) {
          _max = price.amount;
        }
      });
    }

    setTableData(_tableData);
    setChartData([_chartData]);

    if (chartOptionsTimeout.current) {
      clearTimeout(chartOptionsTimeout.current);
    }

    setChartOptions({
      ..._chartOptions,
      chart: {
        ..._chartOptions.chart,
        events: {
          markerClick: (
            event,
            chartContext,
            { seriesIndex, dataPointIndex, config }
          ) => {
            const clickedMonth = _tableData[summaryRow[dataPointIndex]];

            // setExpandedDetails({
            //   ...expandedDetails,
            //   selectedUsageOverride: offerCalc.round(clickedMonth.usage, 0)
            // });
          },
          mounted: function (chart) {
            chartApi.current = chart;
          }
        }
      },
      yaxis: {
        ..._chartOptions.yaxis,
        max: Math.ceil(_max / 100) * 100,
        tickAmount: 5,
        labels: {
          formatter: dollarsFormatter
        }
      }
    });
  }

  return tableData && chartData && activeTab ? (
    <>
      <div
        className={
          'chart home-services ' +
          (desktop ? 'desktop' : 'mobile') +
          (dataExpanded ? ' data-expanded' : '') +
          (offer.TermLength <= 1 &&
          !offer.AutoPayDiscount &&
          !offer.InternetRequired &&
          !offer.InstallationFee
            ? ' no-top-details'
            : '')
        }
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}>
        <div className="offer-section internet">
          {
            offer.FeaturesLabel ||
            offer.DisclosuresLabel ||
            offer.ChannelsLabel ? (
              <Tabs
                value={activeTab}
                onChange={(event, newValue) => setActiveTab(newValue)}
                centered
                className="fixed-text-tabs">
                {/* <Tab label="Pricing Summary" value="chart" /> */}
                {offer.FeaturesLabel ? (
                  <Tab label={offer.FeaturesLabel} value="features" />
                ) : null}
                {offer.DisclosuresLabel ? (
                  <Tab label={offer.DisclosuresLabel} value="disclosures" />
                ) : null}
                {offer.ChannelsLabel && offer.ChannelsLinkUrl ? (
                  <Button
                    key={'channel-link-' + offer.OfferID}
                    href={offer.ChannelsLinkUrl}
                    target="mi_offsite"
                    onClick={(event) =>
                      window.open(offer.ChannelsLinkUrl, 'sc-offsite')
                    }>
                    {offer.ChannelsLabel}
                  </Button>
                ) : null}
              </Tabs>
            ) : null
            // <h5>
            //   <span>
            //     <span>Pricing Summary</span>
            //   </span>
            // </h5>
          }

          {activeTab !== 'None' ? (
            <IconButton
              className="chart-close"
              onClick={(event) => {
                setActiveTab('None');
                event.preventDefault();
                event.stopPropagation();
              }}>
              <CloseIcon />
            </IconButton>
          ) : null}

          {activeTab === 'chart' ? (
            <div className="tab-section">
              {offer.termText ? (
                <p dangerouslySetInnerHTML={{ __html: offer.termText }}></p>
              ) : null}

              <div className="data-container">
                {tableData ? (
                  <>
                    <div className="chart-container">
                      <LoadableChart
                        options={chartOptions}
                        series={chartData}
                        type="line"
                      />
                    </div>

                    <div className="data-detail">
                      Chart based on Projections
                      <Button onClick={() => setDataExpanded(!dataExpanded)}>
                        {dataExpanded ? 'Hide' : 'Show'} data <ExpandMoreIcon />
                      </Button>
                      <div className="table">
                        <TableContainer>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Month</TableCell>
                                <TableCell>Price</TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {tableData.map((price, rowIndex) =>
                                price ? (
                                  <TableRow
                                    onClick={() => {
                                      chartApi.current.toggleDataPointSelection(
                                        1,
                                        rowIndex
                                      );

                                      // setExpandedDetails({
                                      //   ...expandedDetails,
                                      //   selectedUsageOverride:
                                      //     tableData[row].usage
                                      // });
                                    }}>
                                    <TableCell>
                                      Month {price.monthNumber}
                                    </TableCell>
                                    <TableCell>
                                      {'$' +
                                        offerCalc
                                          .round(price.amount, 2)
                                          .toFixed(2)}
                                    </TableCell>
                                  </TableRow>
                                ) : null
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          ) : activeTab === 'features' ? (
            <div
              className="tab-section fixed-text"
              dangerouslySetInnerHTML={{ __html: offer.FeaturesText }}></div>
          ) : activeTab === 'disclosures' ? (
            <div
              className="tab-section fixed-text"
              dangerouslySetInnerHTML={{ __html: offer.DisclosuresText }}></div>
          ) : activeTab === 'channels' ? (
            <div
              className="tab-section fixed-text"
              dangerouslySetInnerHTML={{ __html: offer.ChannelsText }}></div>
          ) : null}
        </div>
      </div>
    </>
  ) : null;
}

function mapStateToProps(state) {
  return {
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(InternetChart);

const LoadableChart = Loadable(() => import('react-apexcharts'));
