import { combineReducers } from 'redux';
import ThemeReducer from '../ThemeReducer';
import webSocket from './webSocketReducer';
import authUser from './userAuthReducer';
import serviceAddress from './serviceAddressReducer';
import commonData from './commonDataReducer';
import residentOnboarding from './residentOnboardingReducer';
import partnerData from './partnerDataReducer';

const rootReducer = combineReducers({
  ThemeOptions: ThemeReducer.ThemeOptions,
  webSocket: webSocket,
  authUser: authUser,
  serviceAddress: serviceAddress,
  commonData: commonData,
  residentOnboarding: residentOnboarding,
  partnerData: partnerData
});

export default rootReducer;
