import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';
import { TextField } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';

import ContactForm from '../ContactForm';

function ContactPage({ siteConfig, title, children }) {
  const [request, setRequest] = useState({});
  const [requestedData, setRequestedData] = useState({});

  useEffect(() => {
    setRequest({
      ...request,
      ['Options Selected']: Object.keys(requestedData)
        .filter((key) => requestedData[key])
        .join(', ')
    });
  }, [requestedData]);

  return (
    <ContactForm
      title="Contact Us"
      subject="Main Contact Form"
      request={request}
      setRequest={setRequest}
      intro={
        <>
          {title ? (
            <h2>{title}</h2>
          ) : (
            <img
              src={withPrefix('/images/misc/wereheretohelp.png?v=1')}
              className="here-to-help"
            />
          )}

          <p style={{ textAlign: 'center' }}>
            If you're interested in learning more about {siteConfig.brand} and
            how we can help both you and your residents, just submit this online
            form and we'll get back to you to answer all your questions.
            <br />
            <br />
            You may also email us at{' '}
            <a href={'mailto:support@' + siteConfig.domain} className="link">
              {'support@' + siteConfig.domain}
            </a>
            <br />
            or call us at{' '}
            <a href="tel:888-969-4367" className="link">
              (888) 969-4367
            </a>
          </p>
          {children ? children : null}
        </>
      }
      fields={
        <>
          <TextField
            variant="outlined"
            label="Phone"
            fullWidth
            value={request['Phone']}
            onChange={(event) =>
              setRequest({ ...request, ['Phone']: event.target.value })
            }
            required
          />

          {/* <FormGroup>
            <FormLabel component="legend">Select all that apply: *</FormLabel>
            <FormControlLabel
              control={<Checkbox />}
              label={"I'm ready to sign up - what's the next step?"}
              onChange={(event) =>
                setRequestedData({
                  ...requestedData,
                  ["I'm ready to sign up - what's the next step?"]:
                    event.target.checked
                })
              }
            />
            <FormControlLabel
              control={<Checkbox />}
              label={"I'd like you to call me."}
              onChange={(event) =>
                setRequestedData({
                  ...requestedData,
                  ["I'd like you to call me."]: event.target.checked
                })
              }
            />
            <FormControlLabel
              control={<Checkbox />}
              label={"I'd like to schedule a full demo."}
              onChange={(event) =>
                setRequestedData({
                  ...requestedData,
                  ["I'd like to schedule a full demo."]: event.target.checked
                })
              }
            />
            <FormControlLabel
              control={<Checkbox />}
              label={"I'd like more information emailed to me."}
              onChange={(event) =>
                setRequestedData({
                  ...requestedData,
                  ["I'd like more information emailed to me."]:
                    event.target.checked
                })
              }
            />
          </FormGroup> */}

          <TextField
            variant="outlined"
            label="Message"
            fullWidth
            value={request['Message']}
            multiline={true}
            onChange={(event) =>
              setRequest({ ...request, ['Message']: event.target.value })
            }
            required
          />
        </>
      }
      requiredFieldList={['Phone', 'Message']}
    />
  );
}

function mapStateToProps(state) {
  return {
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {}
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactPage);
