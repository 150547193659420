import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import InvitationRequest from '../InvitationRequest';
import ContactForm from '../ContactForm';

function DemoModePopup({ closeFunc, siteConfig, useContactForm }) {
  const [request, setRequest] = useState({});

  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      className="demo-mode-popup"
      fullWidth={true}>
      <DialogTitle id="form-dialog-title">
        <IconButton
          style={{ float: 'right' }}
          aria-label="close"
          onClick={() => {
            closeFunc();
          }}>
          <CloseIcon />
        </IconButton>
        <img src={withPrefix('/images/logo-icon.svg')} className="logo-icon" />
        <div>Demo Mode</div>
      </DialogTitle>
      <DialogContent width="100%" style={{ paddingTop: '20px' }}>
        {useContactForm ? (
          <>
            <p>
              You're using a demo version of {siteConfig.brand}. If you'd like
              to experience the full version, please enter your contact
              informaton below or call us at{' '}
              <a
                style={{ whiteSpace: 'nowrap' }}
                href={'tel:' + siteConfig.phone}>
                {siteConfig.phone}
              </a>
            </p>
            <ContactForm
              subject={'Contact ' + siteConfig.brand}
              request={request}
              fullWidth
              setRequest={setRequest}
            />
          </>
        ) : (
          <>
            <p>
              You're using a demo version of {siteConfig.brand}. If you'd like
              to experience the full version, please enter your email address
              below and an access code will be sent to you.
            </p>

            <InvitationRequest inline />
          </>
        )}
        {/* <AddressSearch modal={true} mode="address" hideHistory /> */}
      </DialogContent>
    </Dialog>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {}
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DemoModePopup);
