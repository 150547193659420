import React, { Fragment, useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby-link';
import clsx from 'clsx';
import { Link } from 'gatsby';
import MenuIcon from '@mui/icons-material/Menu';
import { withPrefix } from 'gatsby-link';
import { globalHistory } from '@reach/router/lib/history';

import {
  Hidden,
  IconButton,
  AppBar,
  Box,
  Button,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { connect } from 'react-redux';

import { setSidebarToggleMobile } from '../../redux/ThemeOptions';

import HeaderLogo from '../HeaderLogo';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

import ServiceAddressBox from '../ServiceAddressBox';
import AddressSearch from '../AddressSearch';

import UserProfile from '../UserProfile';
import HeaderUserAuth from '../HeaderUserAuth';
import PropertyMenuPopover from '../PropertyMenu/PropertyMenuPopover';
import LoginButton from '../LoginButton';

import * as dataApi from '../../api/dataApi';
import { saveUserEvent } from '../../api/userAuthApi';

function Header({
  isCollapsedLayout,
  headerShadow,
  headerFixed,
  sidebarToggleMobile,
  setSidebarToggleMobile,
  serviceAddress,
  siteConfig,
  webSocket,
  authUser
}) {
  const ref = useRef();
  const profileKeyCookie = dataApi.getCookie('sc-profile-key');

  const [showAddressSearch, setShowAddressSearch] = useState(null);
  const [addressSearched, setAddressSearched] = useState(false);
  const [showAddressBox, setShowAddressBox] = useState(false);
  const [showBackToDashboard, setShowBackToDashboard] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const [navOpen, setNavOpen] = useState(false);
  const [showUserProfile, setShowUserProfile] = useState(null);
  const [profileKey, setProfileKey] = useState(null);
  const [lastRoute, setLastRoute] = useState(null);
  const _lastRoute = dataApi.getSessionStorage('sc-last-route');
  const [isSticky, setIsSticky] = useState(false);

  const [route, setRoute] = useState(dataApi.getCurrentRoute());

  useEffect(() => {
    trackPageView();

    globalHistory.listen((location) => {
      trackPageView();
      setRoute(dataApi.getCurrentRoute());
    });
  }, []);

  useEffect(() => {
    if (_lastRoute) {
      setLastRoute(_lastRoute);
    } else {
      setLastRoute(null);
    }
  }, [_lastRoute]);

  useEffect(() => {
    let observer = null;

    try {
      const cachedRef = ref.current;
      observer = new IntersectionObserver(
        ([e]) => {
          setIsSticky(e.intersectionRatio < 1);
        },
        { threshold: [1] }
      );

      observer.observe(cachedRef);
    } catch (e) {}

    // unmount
    return function () {
      if (observer) {
        observer.unobserve(cachedRef);
      }
    };
  }, []);

  useEffect(() => {
    let _showAddressBox =
      serviceAddress &&
      serviceAddress.Address &&
      serviceAddress.Address.Address;
    //&& (route !== '/' || siteConfig.id === 'mi')

    setShowAddressBox(_showAddressBox);

    let _showBackToDashboard = true;
    let _showMenu = true;
    let _route = route;
    if (webSocket.mockRoute) {
      _route = webSocket.mockRoute;
    }

    if (siteConfig.id === 'sc') {
      _showMenu = false;
    }

    switch (_route) {
      case '/':
      case '/dashboard':
        _showBackToDashboard = false;
        break;
      case '/demo1':
      case '/demo2':
        _showBackToDashboard = false;
        break;
      default:
        if (
          _route.indexOf('/property-') === 0 ||
          _route.indexOf('/company-') === 0
        ) {
          _showBackToDashboard = false;
          _showMenu = false;
        }
        break;
    }

    setShowBackToDashboard(_showBackToDashboard);
    setShowMenu(_showMenu);

    if (siteConfig.id !== 'sc') {
      dataApi.setServiceAddressTitle(serviceAddress, route);
    }
  }, [route, serviceAddress, webSocket]);

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  function trackPageView() {
    saveUserEvent('UserAction', 'PageView', window.location.href);
  }

  return (
    <AppBar
      color="default"
      className={clsx('app-header', {}) + (isSticky ? ' is-sticky' : '')}
      position="sticky"
      elevation={headerShadow ? 11 : 3}
      ref={ref}>
      <div
        className={
          'header-container ' +
          (showAddressBox ? ' with-address' : '') +
          ((showAddressBox || lastRoute) && route.indexOf('/dashboard') === -1
            ? ' not-dashboard'
            : '')
        }>
        {!showBackToDashboard ? <PropertyMenuPopover /> : null}

        {showBackToDashboard ? (
          <Button
            className="back-button"
            href="/dashboard"
            onClick={(event) => {
              if (webSocket && webSocket.closeFunc && serviceAddress.mock) {
                webSocket.closeFunc();
                event.preventDefault();
              } else if (serviceAddress && serviceAddress.Address) {
                navigate('/dashboard');
                event.preventDefault();
              }
            }}>
            <ArrowBackIcon />
            <span className="hide-mobile"> Back to Dashboard </span>
          </Button>
        ) : lastRoute && lastRoute.indexOf('/property-') !== -1 ? (
          <Button
            className="back-button"
            onClick={() => {
              dataApi.setSessionStorage('sc-last-route', '');
              navigate(lastRoute);
            }}>
            <ArrowBackIcon />
            <span className="hide-mobile"> Back to Property </span>
          </Button>
        ) : null}

        {!isCollapsedLayout && <HeaderLogo route={route} />}

        {authUser &&
        authUser.User &&
        authUser.User.Token &&
        authUser.User.Email !== 'demo@movingin.com' ? (
          <HeaderUserAuth />
        ) : siteConfig.id === 'sc' ? (
          <LoginButton />
        ) : null}

        {showMenu ? (
          <div className={'nav' + (navOpen ? ' open' : '')}>
            <div className="nav-cover" onClick={() => setNavOpen(false)}></div>
            <Button className="menu-icon" onClick={() => setNavOpen(!navOpen)}>
              <MenuIcon />
            </Button>
            <div className="buttons">
              {serviceAddress && serviceAddress.Address ? (
                <Button
                  onClick={() => {
                    setShowUserProfile(true);
                    setNavOpen(false);
                  }}>
                  My Profile
                </Button>
              ) : null}
              <Link to="/contact">
                <Button>Contact</Button>
              </Link>
              {!showAddressBox ? (
                <>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setShowAddressSearch(true);
                      setNavOpen(false);
                    }}>
                    Get Started
                  </Button>
                  <Dialog
                    open={showAddressSearch}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    className={
                      'address-search' +
                      (addressSearched ? ' address-searched' : '')
                    }>
                    <DialogTitle id="form-dialog-title">
                      <img
                        src={withPrefix('/images/logo-icon.svg')}
                        className="logo-icon"
                      />
                      Get Started
                      <IconButton
                        style={{ float: 'right' }}
                        aria-label="close"
                        onClick={() => {
                          setShowAddressSearch(false);
                          setAddressSearched(false);
                        }}>
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent width="100%">
                      <AddressSearch
                        modal={true}
                        mode={
                          siteConfig && siteConfig.id === 'mi'
                            ? 'address'
                            : 'zip'
                        }
                        onSelected={() => {
                          setShowAddressSearch(false);
                          setAddressSearched(false);
                        }}
                        addressLabel={'Enter your address here to get started'}
                        onAddressSearched={() => setAddressSearched(true)}
                      />
                    </DialogContent>
                  </Dialog>
                </>
              ) : null}
            </div>
          </div>
        ) : null}

        {showAddressBox ? (
          <ServiceAddressBox
            route={route}
            setShowUserProfile={setShowUserProfile}
            showMyProfile={
              showMenu &&
              serviceAddress &&
              serviceAddress.Address &&
              serviceAddress.Address.Address
            }
          />
        ) : null}

        {showUserProfile ? (
          <UserProfile closeFunc={() => setShowUserProfile(false)} />
        ) : null}
      </div>
    </AppBar>
  );
}

const mapStateToProps = (state) => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerFixed: state.ThemeOptions.headerFixed,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  serviceAddress: state.serviceAddress,
  siteConfig: state.commonData.siteConfig,
  webSocket: state.webSocket,
  authUser: state.authUser
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
