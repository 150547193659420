import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function serviceAddressReduder(
  state = initialState.residentOnboarding,
  action
) {
  switch (action.type) {
    case types.RESIDENT_ONBOARDING_UPDATED: {
      return {
        ...action.residentOnboarding
      };
    }

    case types.RESIDENT_ONBOARDING_HEADER_UPDATED: {
      return {
        ...state,
        Header: { ...action.header }
      };
    }

    case types.RESIDENT_ONBOARDING_CHECKLIST_UPDATED: {
      return {
        ...state,
        Checklist: [].concat(action.checklist)
      };
    }

    case types.RESIDENT_ONBOARDING_PROVIDERSERVICE_UPDATED: {
      return {
        ...state,
        ProviderServiceList: state.ProviderServiceList.map((providerService) =>
          providerService.ProviderServiceID ===
          action.providerService.ProviderServiceID
            ? { ...action.providerService }
            : { ...providerService }
        )
      };
    }

    case types.RESIDENT_ONBOARDING_VEHICLE_UPDATED: {
      return {
        ...state,
        VehicleList: state.VehicleList.map((vehicle) =>
          vehicle.ResidentVehicleID === action.vehicle.ResidentVehicleID
            ? { ...action.vehicle }
            : { ...vehicle }
        )
      };
    }

    case types.RESIDENT_ONBOARDING_VEHICLE_ADDED: {
      return {
        ...state,
        VehicleList: state.VehicleList.concat({ ...action.vehicle })
      };
    }

    case types.RESIDENT_ONBOARDING_VEHICLE_DELETED: {
      return {
        ...state,
        VehicleList: state.VehicleList.filter(
          (vehicle) =>
            vehicle.ResidentVehicleID !== action.vehicle.ResidentVehicleID
        )
      };
    }

    case types.RESIDENT_ONBOARDING_PET_UPDATED: {
      return {
        ...state,
        PetList: state.PetList.map((pet) =>
          pet.ResidentPetID === action.pet.ResidentPetID
            ? { ...action.pet }
            : { ...pet }
        )
      };
    }

    case types.RESIDENT_ONBOARDING_PET_ADDED: {
      return {
        ...state,
        PetList: state.PetList.concat({ ...action.pet })
      };
    }

    case types.RESIDENT_ONBOARDING_PET_DELETED: {
      return {
        ...state,
        PetList: state.PetList.filter(
          (pet) => pet.ResidentPetID !== action.pet.ResidentPetID
        )
      };
    }

    case types.RESIDENT_ONBOARDING_FILELIST_UPDATED: {
      return {
        ...state,
        FileList: [].concat(action.fileList)
      };
    }

    case types.RESIDENT_ONBOARDING_CLEARED: {
      return null;
    }

    default:
      return state;
  }
}
