import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function userAuthReducer(state = initialState.authUser, action) {
  switch (action.type) {
    case types.USER_AUTH_SUCCESS:
    case types.USER_AUTH_FAILURE:
      return { ...action.authUser };
    case types.USER_AUTH_LOGOUT:
      return null;
    case types.USER_UNIT_SELECTED:
      return { ...state, selectedUnit: { ...action.unit } };
    default:
      return state;
  }
}
