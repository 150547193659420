import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { navigate } from 'gatsby-link';

import { IconButton, Popover, Button } from '@mui/material';
import TextField from '@mui/material/TextField';

import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';
import * as userAuthActions from '../../redux/actions/userAuthActions';
import * as residentOnboardingActions from '../../redux/actions/residentOnboardingActions';

import UserPropertySelect, {
  selectSampleResident,
  setSelectedResident
} from '../UserPropertySelect';

import PropertyMenu from './index';

import './index.css';

function PropertyMenuPopover({ siteConfig, authUser, actions }) {
  const [showPopover, setShowPopover] = useState(null);

  return authUser &&
    authUser.User &&
    authUser.User.Token &&
    authUser.PropertyList &&
    authUser.PropertyList.length &&
    authUser.User.Email !== 'demo@movingin.com' ? (
    <div className="header-item property-menu">
      <IconButton
        onClick={(event) => {
          setShowPopover(event.currentTarget);
        }}>
        <MenuIcon />
      </IconButton>

      <Popover
        id={showPopover ? 'property-menu' : undefined}
        open={Boolean(showPopover)}
        anchorEl={showPopover}
        onClose={() => setShowPopover(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        PaperProps={{
          style: { width: '100%', maxWidth: '400px' }
        }}>
        <PropertyMenu setShowPopover={setShowPopover} />
      </Popover>
    </div>
  ) : null;
}

const mapStateToProps = (state) => ({
  serviceAddress: state.serviceAddress,
  authUser: state.authUser,
  siteConfig: state.commonData.siteConfig
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      serviceAddressUpdated: bindActionCreators(
        serviceAddressActions.serviceAddressUpdated,
        dispatch
      ),
      selectUnit: bindActionCreators(userAuthActions.selectUnit, dispatch),
      clearResidentOnboarding: bindActionCreators(
        residentOnboardingActions.clearResidentOnboarding,
        dispatch
      )
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertyMenuPopover);
