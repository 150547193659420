import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function commonDataReducer(
  state = initialState.commonData,
  action
) {
  switch (action.type) {
    default:
      return state;
  }
}
