import * as types from '../actions/actionTypes';
import initialState from './initialState';

import * as offerCalc from '../../api/offerCalc';
import { getCurrentRoute, setServiceAddressTitle } from '../../api/dataApi';

export default function serviceAddressReduder(
  state = initialState.serviceAddress,
  action
) {
  switch (action.type) {
    case types.SERVICE_ADDRESS_UPDATED:
      console.log('action.serviceAddress', action.serviceAddress);
      if (
        state &&
        state.mock &&
        !action.serviceAddress.force &&
        !action.serviceAddress.mock
      ) {
        return {
          ...state,
          lastUsedServiceAddress: { ...action.serviceAddress }
        };
      }
      // else if (state && state.demo && !action.serviceAddress.demo) {
      //   return {
      //     ...action.serviceAddress, //For demo, take only the updates other than the address
      //     Address: { ...state.serviceAddress.Address }
      //   };
      // }
      else {
        if (
          !state ||
          !state.Address ||
          action.serviceAddress.Address.Address !== state.Address.Address
        ) {
          if (typeof window !== 'undefined') {
            if (
              action.serviceAddress &&
              action.serviceAddress.Address &&
              action.serviceAddress.Address.Address
            ) {
              console.log('initialState', initialState);
              if (
                initialState &&
                initialState.commonData &&
                initialState.commonData.siteConfig &&
                initialState.commonData.siteConfig.id !== 'sc'
              ) {
                setServiceAddressTitle(action.serviceAddress);
              }

              // //Scroll to the top of the page
              // window.scrollTo(0, 0);
            }
          }
        }

        return {
          ...action.serviceAddress,
          lastUsedServiceAddress: state && !state.mock ? { ...state } : null
        };
      }

    case types.SERVICE_ADDRESS_UPDATED_WEBSOCKET:
      if (
        !state ||
        !state.Address ||
        (action.serviceAddress.Address.Address === state.Address.Address &&
          action.serviceAddress.Address.Zip === state.Address.Zip)
      ) {
        if (window) {
          setServiceAddressTitle(action.serviceAddress);

          if (
            action.serviceAddress &&
            action.serviceAddress.Address &&
            action.serviceAddress.Address.Address
          ) {
            //Scroll to the top of the page
            //Commented out 6/8/2022 - if this is needed, make sure it doesn't happen on the landing page
            //window.scrollTo(0, 0);
          }
        }

        return { ...action.serviceAddress };
      }

      return { ...state };

    case types.VISITOR_USERINFO_UPDATED:
      return {
        ...state,
        Address: {
          ...state.Address,
          ...action.serviceAddress.Address
        },
        placeList: null //Force the moving-related services to find locations again
      };

    case types.SERVICE_ADDRESS_OFFERS_UPDATED:
      let offersChanged = offerCalc.checkOffersChanged(
        action.serviceAddress.OfferList,
        state.OfferList
      );

      if (offersChanged) {
        return {
          ...state,
          OfferList: action.serviceAddress.OfferList,
          OfferDictionary: action.serviceAddress.OfferDictionary,
          offersChanged
        };
      }

      return state;
    case types.SERVICE_ADDRESS_CLEARED:
      return null;
    case types.SERVICE_ADDRESS_CART_UPDATED: {
      return state ? { ...state, Cart: action.cart } : null;
    }
    case types.SERVICE_ADDRESS_TASK_COMPLETE:
      return {
        ...state,
        Checklist: state.Checklist.map((section) => {
          return {
            ...section,
            serviceList: section.serviceList.map((service) => {
              return service.headline === action.service.headline
                ? { ...action.service, completed: action.completed }
                : service;
            })
          };
        })
      };
    case types.SERVICE_ADDRESS_CHECKLIST_UPDATED:
      return state
        ? state.Address &&
          state.Address.Address === action.serviceAddress.Address &&
          state.Address.Zip === action.serviceAddress.Zip
          ? { ...state, Checklist: action.checklist }
          : { ...state }
        : null;
    case types.SERVICE_ADDRESS_SMARTMETER_PROMPTED:
      return state ? { ...state, smartMeterPrompted: true } : null;
    case types.SERVICE_ADDRESS_PLACELIST_UPDATED:
      return state
        ? {
            ...state,
            placeList: state.placeList
              ? { ...state.placeList, ...action.placeList }
              : { ...action.placeList }
          }
        : null;
    case types.SERVICE_ADDRESS_OFFER_EXTRACTION_COMPLETE:
      return state ? { ...state, lastOfferExtraction: action.apiID } : null;
    case types.SERVICE_ADDRESS_TASKSTATUS_UPDATED:
      return state
        ? {
            ...state,
            Checklist: state.Checklist.map((section) => {
              return section.ServiceSectionID ===
                action.section.ServiceSectionID
                ? { ...action.section }
                : { ...section };
            })
          }
        : null;
    default:
      return state;
  }
}
