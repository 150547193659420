export const BEGIN_API_CALL = 'BEGIN_API_CALL';
export const API_CALL_ERROR = 'API_CALL_ERROR';

export const WEB_SOCKET_CONNECTING = 'WEB_SOCKET_CONNECTING';
export const WEB_SOCKET_CONNECTED = 'WEB_SOCKET_CONNECTED';
export const WEB_SOCKET_RECONNECTING = 'WEB_SOCKET_RECONNECTING';
export const WEB_SOCKET_DISCONNECTED = 'WEB_SOCKET_DISCONNECTED';
export const AJAX_CONNECTED = 'AJAX_CONNECTED';
export const WEB_PAGE_LOADING = 'WEB_PAGE_LOADING';
export const INVITATION_FOUND = 'INVITATION_FOUND';
export const MOCK_ROUTE_UPDATED = 'MOCK_ROUTE_UPDATED';
export const LANDING_PAGE_SET = 'LANDING_PAGE_SET';

export const USER_AUTH_SUCCESS = 'USER_AUTH_SUCCESS';
export const USER_AUTH_FAILURE = 'USER_AUTH_LOGOUT';
export const USER_AUTH_LOGOUT = 'USER_AUTH_LOGOUT';
export const USER_MENU_UPDATED = 'USER_MENU_UPDATED';
export const USER_UNIT_SELECTED = 'USER_UNIT_SELECTED';

export const SERVICE_ADDRESS_UPDATED = 'SERVICE_ADDRESS_UPDATED';
export const SERVICE_ADDRESS_UPDATED_WEBSOCKET =
  'SERVICE_ADDRESS_UPDATED_WEBSOCKET';

export const SERVICE_ADDRESS_OFFERS_UPDATED = 'SERVICE_ADDRESS_OFFERS_UPDATED';
export const SERVICE_ADDRESS_OFFER_EXTRACTION_COMPLETE =
  'SERVICE_ADDRESS_OFFER_EXTRACTION_COMPLETE';
export const SERVICE_ADDRESS_CLEARED = 'SERVICE_ADDRESS_CLEARED';
export const SERVICE_ADDRESS_CART_UPDATED = 'SERVICE_ADDRESS_CART_UPDATED';
export const SERVICE_ADDRESS_TASK_COMPLETE = 'SERVICE_ADDRESS_TASK_COMPLETE';
export const SERVICE_ADDRESS_CHECKLIST_UPDATED =
  'SERVICE_ADDRESS_CHECKLIST_UPDATED';
export const SERVICE_ADDRESS_SMARTMETER_PROMPTED =
  'SERVICE_ADDRESS_SMARTMETER_PROMPTED';
export const VISITOR_USERINFO_UPDATED = 'VISITOR_USERINFO_UPDATED';
export const SERVICE_ADDRESS_TASKSTATUS_UPDATED =
  'SERVICE_ADDRESS_TASKSTATUS_UPDATED';

export const SERVICE_ADDRESS_PLACELIST_UPDATED =
  'SERVICE_ADDRESS_PLACELIST_UPDATED';

export const RESIDENT_ONBOARDING_UPDATED = 'RESIDENT_ONBOARDING_UPDATED';
export const RESIDENT_ONBOARDING_HEADER_UPDATED =
  'RESIDENT_ONBOARDING_HEADER_UPDATED';
export const RESIDENT_ONBOARDING_CLEARED = 'RESIDENT_ONBOARDING_CLEARED';

export const RESIDENT_ONBOARDING_CHECKLIST_UPDATED =
  'RESIDENT_ONBOARDING_CHECKLIST_UPDATED';

export const RESIDENT_ONBOARDING_PROVIDERSERVICE_UPDATED =
  'RESIDENT_ONBOARDING_PROVIDERSERVICE_UPDATED';
export const RESIDENT_ONBOARDING_VEHICLE_UPDATED =
  'RESIDENT_ONBOARDING_VEHICLE_UPDATED';
export const RESIDENT_ONBOARDING_VEHICLE_ADDED =
  'RESIDENT_ONBOARDING_VEHICLE_ADDED';
export const RESIDENT_ONBOARDING_VEHICLE_DELETED =
  'RESIDENT_ONBOARDING_VEHICLE_DELETED';

export const RESIDENT_ONBOARDING_PET_UPDATED =
  'RESIDENT_ONBOARDING_PET_UPDATED';
export const RESIDENT_ONBOARDING_PET_ADDED = 'RESIDENT_ONBOARDING_PET_ADDED';
export const RESIDENT_ONBOARDING_PET_DELETED =
  'RESIDENT_ONBOARDING_PET_DELETED';

export const RESIDENT_ONBOARDING_FILELIST_UPDATED =
  'RESIDENT_ONBOARDING_FILELIST_UPDATED';

export const CAMPAIGN_LIST_UPDATED = 'CAMPAIGN_LIST_UPDATED';
