import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';
import moment from 'moment';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Popover
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { setCookie } from '../../api/dataApi';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';

import AddressSearch from '../AddressSearch';
import DefaultStartDateEdit from '../DefaultStartDateEdit';

import './index.css';

function UserProfile({ siteConfig, serviceAddress, closeFunc, authUser }) {
  const [showAddressSearch, setShowAddressSearch] = useState(null);
  const [addressSearched, setAddressSearched] = useState(false);

  return (
    <>
      <Dialog
        open={true}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        className={
          'user-profile-popup' +
          (serviceAddress &&
          serviceAddress.Address &&
          serviceAddress.Address.ResidentID
            ? ' resident'
            : '')
        }>
        <DialogTitle id="form-dialog-title">
          <img
            src={withPrefix('/images/logo-icon.svg')}
            className="logo-icon"
          />
          My Profile
          <IconButton
            style={{ float: 'right' }}
            aria-label="close"
            onClick={() => {
              closeFunc();
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent width="100%">
          <div className={'user-profile'}>
            {serviceAddress &&
            serviceAddress.Address &&
            serviceAddress.Address.ResidentID ? (
              <div className="section from-address">
                {serviceAddress &&
                serviceAddress.Address &&
                serviceAddress.Address.ResidentID &&
                serviceAddress.Address.PropertyName ? (
                  <Alert severity="info">
                    If the information shown below is missing or incorrect,
                    please contact the {serviceAddress.Address.PropertyName}{' '}
                    leasing office
                    {serviceAddress.Address.PropertyPhone ? (
                      <>
                        {' '}
                        at{' '}
                        <a
                          className="link"
                          href={'tel:' + serviceAddress.Address.PropertyPhone}>
                          {serviceAddress.Address.PropertyPhone}
                        </a>
                      </>
                    ) : null}
                    .
                  </Alert>
                ) : null}

                {serviceAddress.Address.ResidentID &&
                (!authUser || !authUser.User || !authUser.User.Token) ? (
                  <Button
                    variant="outlined"
                    color="secondary"
                    className="log-out-button"
                    onClick={() => {
                      setCookie('sc-invitation', '');
                      window.location.reload();
                    }}>
                    Log Out
                  </Button>
                ) : null}

                {serviceAddress.Address.FirstName ? (
                  <h2>
                    {serviceAddress.Address.FirstName}{' '}
                    {serviceAddress.Address.LastName
                      ? serviceAddress.Address.LastName
                      : ''}
                  </h2>
                ) : (
                  <h2>Resident Information</h2>
                )}

                {serviceAddress.Address.ResidentID ? (
                  <>
                    {/* <div className="data-point">
                      <label>Resident ID: </label>
                      <strong>
                        {serviceAddress.Address.TenantId
                          ? serviceAddress.Address.TenantId
                          : 'Not Available'}
                      </strong>
                    </div> */}

                    <div className="data-point">
                      <label>Lease Start Date: </label>
                      <strong>
                        {serviceAddress.Address.DefaultStartDate
                          ? moment
                              .utc(serviceAddress.Address.DefaultStartDate)
                              .format('M/D/YYYY')
                          : 'Not Available'}
                      </strong>
                    </div>

                    <div className="data-point">
                      <label>Email: </label>
                      <strong>
                        {serviceAddress.Address.Email
                          ? serviceAddress.Address.Email
                          : 'Not Available'}
                      </strong>
                    </div>

                    <div className="data-point">
                      <label>Phone: </label>
                      <strong>
                        {serviceAddress.Address.Phone
                          ? serviceAddress.Address.Phone
                          : 'Not Available'}
                      </strong>
                    </div>
                  </>
                ) : null}
              </div>
            ) : null}
            <div className="section from-address">
              {!serviceAddress ||
              !serviceAddress.Address ||
              !serviceAddress.Address.ResidentID ? (
                <Button
                  onClick={() => setShowAddressSearch({ title: 'New Address' })}
                  variant="outlined"
                  color="secondary">
                  Edit Address
                </Button>
              ) : null}

              <h2>
                {siteConfig.id === 'mi' ? 'Moving to:' : 'Current Address'}
              </h2>
              {serviceAddress &&
              serviceAddress.Address &&
              (serviceAddress.Address.Address || serviceAddress.Address.Zip) ? (
                <p>
                  {serviceAddress.Address.Address ? (
                    <>
                      {serviceAddress.Address.Address}
                      <br />
                    </>
                  ) : null}
                  {serviceAddress.Address.Secondary ? (
                    <>
                      {serviceAddress.Address.Secondary}
                      <br />
                    </>
                  ) : null}
                  {serviceAddress.Address.City
                    ? serviceAddress.Address.City + ', '
                    : ''}
                  {serviceAddress.Address.State
                    ? serviceAddress.Address.State + ' '
                    : ''}
                  {serviceAddress.Address.Zip}
                </p>
              ) : (
                <p>Not Set</p>
              )}
            </div>

            {siteConfig.id === 'mi' ? (
              <div className="section from-address">
                <Button
                  onClick={() =>
                    setShowAddressSearch({
                      title: 'Previous Address',
                      isPreviousAddress: true
                    })
                  }
                  variant="outlined"
                  color="secondary">
                  Edit Address
                </Button>
                <h2>Moving from:</h2>
                {serviceAddress &&
                serviceAddress.Address &&
                (serviceAddress.Address.Previous_Address ||
                  serviceAddress.Address.Previous_Zip) ? (
                  <p>
                    {serviceAddress.Address.Previous_Address ? (
                      <>
                        {serviceAddress.Address.Previous_Address}
                        <br />
                      </>
                    ) : null}
                    {serviceAddress.Address.Previous_Secondary ? (
                      <>
                        {serviceAddress.Address.Previous_Secondary}
                        <br />
                      </>
                    ) : null}
                    {serviceAddress.Address.Previous_City
                      ? serviceAddress.Address.Previous_City + ', '
                      : ''}
                    {serviceAddress.Address.Previous_State
                      ? serviceAddress.Address.Previous_State + ' '
                      : ''}
                    {serviceAddress.Address.Previous_Zip}
                  </p>
                ) : (
                  <p>Previous address not set</p>
                )}
              </div>
            ) : null}

            {siteConfig.id === 'mi' &&
            serviceAddress &&
            serviceAddress.Address &&
            !serviceAddress.Address.ResidentID ? (
              <div className="section default-start-date">
                <h2>Moving on:</h2>
                <DefaultStartDateEdit />
              </div>
            ) : null}
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => closeFunc()}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {showAddressSearch ? (
        <Dialog
          open={true}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          className={
            'address-search' + (addressSearched ? ' address-searched' : '')
          }>
          <DialogTitle id="form-dialog-title">
            <img
              src={withPrefix('/images/logo-icon.svg')}
              className="logo-icon"
            />
            {siteConfig.id === 'mi' && showAddressSearch
              ? showAddressSearch.title
              : 'Change Location'}
            <IconButton
              style={{ float: 'right' }}
              aria-label="close"
              onClick={() => {
                setShowAddressSearch(null);
                setAddressSearched(false);
              }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent width="100%">
            <AddressSearch
              modal={true}
              onSelected={() => {
                setShowAddressSearch(false);
                setAddressSearched(false);
                closeFunc();
              }}
              isPreviousAddress={showAddressSearch.isPreviousAddress}
              onAddressSearched={() => setAddressSearched(true)}
            />
          </DialogContent>
        </Dialog>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => ({
  serviceAddress: state.serviceAddress,
  authUser: state.authUser,
  siteConfig: state.commonData.siteConfig
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      clearServiceAddress: bindActionCreators(
        serviceAddressActions.clearServiceAddress,
        dispatch
      ),
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
