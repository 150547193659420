import React from 'react';
import wrapWithProvider from './wrapWithProvidersClient';

export const wrapRootElement = wrapWithProvider;

// export const wrapPageElement = ({ element }) => (
//   // <div className="hello-there">hi hi! {element}</div>
//   <App>{element}</App>
// );

// const $ = require('jquery');

// export const onInitialClientRender = () => {
//   // $(document).ready(function() {
//   //   console.log("The answer is don't think about it!");
//   // });
// };

// LOG TO THE CONSOLE THE START AND END TIMES:
// let navigationStartTime;
// export const onPreRouteUpdate = ({ location }) => {
//   navigationStartTime = new Date().toISOString();
//   console.log(`Navigation started at ${navigationStartTime}`);
// };

// let navigationEndTime;
// export const onRouteUpdate = () => {
//   navigationEndTime = new Date().toISOString();
//   console.log(`Navigation ended at: ${navigationEndTime}`);
// };
