import React, { useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { TextField, Button } from '@mui/material';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as dataApi from '../../api/dataApi';

import './index.css';

function ContactForm({
  siteConfig,
  title,
  intro,
  fields,
  subject,
  request,
  setRequest,
  requiredFieldList,
  fullWidth
}) {
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  requiredFieldList = ['First Name', 'Last Name', 'Email'].concat(
    requiredFieldList ? requiredFieldList : []
  );

  function checkFormPopulated() {
    return (
      request &&
      requiredFieldList.filter((field) => !request[field]).length === 0
    );
  }

  return (
    <div
      className={'page-container form-container' + (fullWidth ? ' full' : '')}>
      <h1>{title}</h1>
      <div className="page">
        {submitted ? (
          <div className="inner">
            Thank you! Your information has been submitted, and we'll follow up
            with you shortly.
          </div>
        ) : (
          <div className="inner">
            {intro ? <div className="contact-message">{intro}</div> : null}

            <div className="form">
              <TextField
                variant="outlined"
                label="First Name"
                required
                fullWidth
                value={request['First Name']}
                onChange={(event) =>
                  setRequest({ ...request, ['First Name']: event.target.value })
                }
              />

              <TextField
                variant="outlined"
                label="Last Name"
                required
                fullWidth
                value={request['Last Name']}
                onChange={(event) =>
                  setRequest({ ...request, ['Last Name']: event.target.value })
                }
              />

              <TextField
                variant="outlined"
                label="Company"
                fullWidth
                value={request['Company']}
                onChange={(event) =>
                  setRequest({ ...request, ['Company']: event.target.value })
                }
                required
              />

              <TextField
                variant="outlined"
                label="Email"
                required
                fullWidth
                value={request['Email']}
                onChange={(event) =>
                  setRequest({ ...request, ['Email']: event.target.value })
                }
              />

              {fields ? fields : null}

              <Button
                variant="contained"
                color="secondary"
                disabled={submitting || !checkFormPopulated()}
                onClick={() => {
                  setSubmitting(true);
                  dataApi
                    .saveInquiry(subject, request)
                    .then((result) => setSubmitted(true));
                }}>
                {submitting ? 'Submitting...' : 'Submit'}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
