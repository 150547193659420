import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as userAuthActions from '../../redux/actions/userAuthActions';

import { validateEmail, validatePassword, logIn } from '../../api/userAuthApi';

import UserForgotPassword from '../UserForgotPassword';

import './index.css';

function LoginForm({ siteConfig, serviceAddress, actions, afterLoginFunc }) {
  const [request, setRequest] = useState({});
  const [showPasswordHelp, setShowPasswordHelp] = useState(null);
  const [userError, setUserError] = useState(null);

  function logUserIn(email, password) {
    setUserError(null);
    setRequest({ ...request, LoggingIn: true });
    logIn(email, password).then((result) => {
      setRequest({ ...request, LoggingIn: false });

      if (
        result &&
        result.User &&
        result.User.Token &&
        result.PropertyList &&
        result.PropertyList.length
      ) {
        actions.userLoggedIn(result);
        if (afterLoginFunc) {
          afterLoginFunc(result);
        }
      } else {
        setUserError(<>Invalid email address or password.</>);
      }
    });
  }

  function checkDisabled() {
    return (
      !request.Email ||
      !request.Password ||
      !validateEmail(request.Email) ||
      !validatePassword(request.Password) ||
      request.LoggingIn
    );
  }

  return (
    <div className="main-login-form">
      {userError ? (
        <Alert severity="error" style={{ marginBottom: '20px' }}>
          {userError}
        </Alert>
      ) : null}

      <TextField
        value={request.Email}
        id="username"
        name="username"
        required
        label="Your Email"
        onChange={(event) =>
          setRequest({ ...request, Email: event.target.value })
        }
        type="email"
        fullWidth
      />

      <TextField
        value={request.Password}
        id="password"
        name="password"
        label="Your Password"
        type={request.ShowPassword ? 'text' : 'password'}
        required
        onChange={(event) =>
          setRequest({ ...request, Password: event.target.value })
        }
        helperText={
          <>
            <a className="link" onClick={() => setShowPasswordHelp(true)}>
              Forgot password?
            </a>
          </>
        }
        onKeyDown={(event) => {
          if (event.keyCode === 13) {
            if (!checkDisabled()) {
              logUserIn(request.Email, request.Password);
            }
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() =>
                  setRequest({
                    ...request,
                    ShowPassword: !request.ShowPassword
                  })
                }>
                {request.ShowPassword ? (
                  <VisibilityOffIcon />
                ) : (
                  <VisibilityIcon />
                )}
              </IconButton>
            </InputAdornment>
          )
        }}
        fullWidth
      />

      <Button
        variant="contained"
        color="primary"
        className="login-button"
        disabled={checkDisabled()}
        onClick={() => logUserIn(request.Email, request.Password)}>
        {request.LoggingIn ? 'Logging in...' : 'Log In'}
      </Button>

      {showPasswordHelp ? (
        <UserForgotPassword
          email={request.Email}
          closeFunc={() => setShowPasswordHelp(null)}
          passwordUpdatedFunc={(_request) => {
            logUserIn(_request.Email, _request.NewPassword);
            setShowPasswordHelp(null);
          }}
        />
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      userLoggedIn: bindActionCreators(userAuthActions.userLoggedIn, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
