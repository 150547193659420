import * as types from './actionTypes';
import showNotification from '../../components/Common/Notification';

const openNotification = (message, duration) => {
  var params = {
    key: 'webSocketNotice',
    message: message
  };

  if (duration !== undefined) {
    params.duration = duration;
  }

  showNotification(params);
};

export function webSocketConnecting() {
  return { type: types.WEB_SOCKET_CONNECTING, connectionId: null };
}

export function pageLoading(isLoading, loadingMessage) {
  return { type: types.WEB_PAGE_LOADING, isLoading, loadingMessage };
}

export function mockRouteUpdated(mockRoute, closeFunc) {
  return { type: types.MOCK_ROUTE_UPDATED, mockRoute, closeFunc };
}

export function webSocketConnected(connectionId, forceUpdate) {
  return {
    type: types.WEB_SOCKET_CONNECTED,
    connectionId: connectionId,
    forceUpdate: forceUpdate ? new Date() : null
  };
}

export function invitationFound(invitation) {
  return { type: types.INVITATION_FOUND, invitation };
}

export function webSocketDisconnected() {
  openNotification(
    'Unable to connect for live updates. You are offline or the service is down. Please refresh the page.',
    0
  );
  return { type: types.WEB_SOCKET_DISCONNECTED, connectionId: null };
}

export function ajaxConnected(hasServiceAddress) {
  return { type: types.AJAX_CONNECTED, hasServiceAddress };
}

export function webSocketReconnecting() {
  //openNotification('Disconnected from live updates. Reconnecting...');
  return { type: types.WEB_SOCKET_RECONNECTING, connectionId: null };
}

export function landingPageSet(landingPage) {
  return { type: types.LANDING_PAGE_SET, landingPage };
}

export function setLandingPage(landingPage) {
  return function (dispatch, getState) {
    dispatch(landingPageSet(landingPage));
  };
}
