import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function partnerDataReducer(
  state = initialState.partnerData,
  action
) {
  switch (action.type) {
    case types.CAMPAIGN_LIST_UPDATED: {
      return {
        ...state,
        campaignList: action.campaignList
      };
    }

    default:
      return state;
  }
}
