import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import configureStore from './src/redux/configureStore';
import WebSocketProvider, { WebSocketContext } from './src/WebSockets';

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = configureStore();
  return (
    <ReduxProvider store={store}>
      <WebSocketProvider>{element}</WebSocketProvider>
    </ReduxProvider>
  );
};
